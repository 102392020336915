import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler, APP_INITIALIZER} from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoPreloading, RouterModule } from '@angular/router';
import { AuthGuard } from './utility/guard/auth.guard';
import { RoleGuard } from './utility/guard/role.guard';
import { AppComponent } from './app.component';
import { SidenavComponent } from './views/features/sidenav/sidenav.component';
// Services
import { DashboardService } from './dashboard/dashboard.service';
import { ApiService } from './utility/services/api.service';
import { NumberFormatter } from '../global';
import { DataService } from './utility/services/data.service';
import { GenericInputModelComponent } from './shared/generic-input-model/generic-input-model.component';
import { SharedModule } from './shared/shared.module';
import { ModalService } from './utility/services/modal.service';
import { CongratsPopupComponent } from './dashboard/congrats-popup/congrats-popup.component';
import { NavigationHeaderComponent } from './views/features/navigation-header/navigation-header.component';
import { PlansComponent } from './views/shared/tms-plan/plans/plans.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SentryErrorHandler } from './views/shared/services/sentry-error-handler.service';
@NgModule({
    declarations: [AppComponent, GenericInputModelComponent, CongratsPopupComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        NavigationHeaderComponent,
        SidenavComponent,
        PlansComponent,
        RouterModule.forRoot(
            [
                {
                    path: 'login',
                    loadChildren: () => import('./dashboard/login/login.module').then((m) => m.LoginModule)
                },
                {
                    path: 'sign-up',
                    loadChildren: () => import('./dashboard/sign-up/sign-up.module').then((m) => m.ThriveSignUpModule)
                },
                {
                    path: 'thrive-step-form',
                    canActivate: [AuthGuard],
                    loadChildren: () =>
                        import(
                            './views/features/sign-up-steps/sign-up-steps.routes'
                        ).then((m) => m.SIGN_UP_STEPS_ROUTES),
                },
                {
                    path: '',
                    canActivate: [AuthGuard, RoleGuard],
                    component: SidenavComponent,
                    children: [
                        {
                            path: 'overview',
                            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
                        },
                        {
                            path: 'integrate-pos',
                            loadChildren: () => import('./dashboard/integrate-pos/integrate-pos.module').then((m) => m.IntegratePosModule)
                        },
                        {
                            path: 'business-intelligence',
                            loadChildren: () => import('./dashboard/business-intelligence/business-intelligence.module').then((m) => m.BusinessIntelligenceModule)
                        },
                        {
                            path: 'thrive-metrics',
                            loadChildren: () => import('./views/features/thrive-metrics/thrive-metrics-routes').then((m) => m.THRIVE_METRICS_ROUTES)
                        },
                        {
                            path: 'leaderboard',
                            loadChildren: () => import('./dashboard/leaderboard/leaderboard.module').then((m) => m.LeaderboardModule)
                        },
                        {
                            path: 'customers',
                            loadChildren: () => import('./views/features/customers/customers-routes').then((m) => m.CUSTOMERS_ROUTES)
                        },
                        {
                            path: 'loyalty',
                            loadChildren: () => import('./dashboard/loyalty/loyalty.module').then((m) => m.LoyaltyModule)
                        },
                        {
                            path: 'facebook-ad',
                            loadChildren: () => import('./dashboard/facebook-ad/facebook-ad.module').then((m) => m.FacebookAdModule)
                        },
                        {
                            path: 'referrals',
                            loadChildren: () => import('./dashboard/referrals/referrals.module').then((m) => m.ReferralsModule)
                        },
                        {
                            path: 'feedback',
                            loadChildren: () => import('./dashboard/feedback/feedback.module').then((m) => m.FeedbackModule)
                        },
                        {
                            path: 'campaigns',
                            loadChildren: () => import('./dashboard/campaign/campaign.module').then((m) => m.CampaignModule)
                        },
                        {
                            path: 'templates',
                            loadChildren: () => import('./dashboard/template/template.module').then((m) => m.TemplateModule)
                        },
                        {
                            path: 'auto-engage',
                            loadChildren: () => import('./dashboard/auto-engage/auto-engage.module').then((m) => m.AutoEngageModule)
                        },
                        {
                            path: 'online-presence',
                            loadChildren: () => import('./dashboard/partoo/partoo.module').then((m) => m.PartooModule)
                        },
                        {
                            path: 'generate-report',
                            loadChildren: () => import('./views/features/generate-report/generate-report-routes').then((m) => m.GENERATE_REPORT_ROUTES)
                        },
                        {
                            path: 'menu-management',
                            loadChildren: () => import('./dashboard/menu-management/menu-management.module').then((m) => m.MenuManagementModule)
                        },
                        {
                            path: 'item-availability',
                            loadChildren: () => import('./dashboard/item-availability/item-availability.module').then((m) => m.ItemAvailabilityModule)
                        },
                        {
                            path: 'item-charges',
                            loadChildren: () => import('./views/features/item-charge/charges-routes').then((m) => m.CHARGES_ROUTES)
                        },
                        {
                            path: 'brand',
                            loadChildren: () => import('./views/features/brand/brand.routes').then((m) => m.BRAND_ROUTES)
                        },
                        {
                            path: 'past-order',
                            loadChildren: () => import('./views/features/past-order/past-order-routes').then((m) => m.PAST_ORDER_ROUTES)
                        },
                        {
                            path: 'offers',
                            loadChildren: () => import('./views/features/offers/offers-routes').then((m) => m.OFFERS_ROUTES)
                        },
                        {
                            path: 'banners',
                            loadChildren: () => import('./views/features/banners/banners-routes').then((m) => m.BANNERS_ROUTES)
                        },
                        {
                            path: 'settings',
                            loadChildren: () => import('./dashboard/settings/settings.module').then((m) => m.SettingsModule)
                        },
                        {
                            path: 'upload',
                            loadChildren: () => import('./dashboard/upload/upload-routes').then((m) => m.UPLOAD_ROUTES)
                        },
                        {
                            path: 'upload/contacts',
                            redirectTo: 'upload'
                        },
                        {
                            path: 'upload/transactions',
                            redirectTo: 'upload'
                        },
                        {
                            path: 'marketing-material',
                            loadChildren: () => import('./views/features/marketing-material/marketing-material-routes').then((m) => m.MARKETING_MATERIAL_ROUTES)
                        },
                        {
                            path: 'customer-search',
                            loadChildren: () => import('./dashboard/search-details/search-details.module').then((m) => m.SearchDetailsModule)
                        },
                        {
                            path: 'social-sharing',
                            loadChildren: () => import('./dashboard/social-sharing/social-sharing.module').then((m) => m.SocialSharingModule)
                        },
                        {
                            path: 'wallet',
                            loadChildren: () => import('./dashboard/wallet/wallet-routes').then((m) => m.WALLET_ROUTES)
                        },
                        {
                            path: '',
                            redirectTo: 'overview',
                            pathMatch: 'full'
                        },
                        {
                            path: '**',
                            redirectTo: 'overview',
                            pathMatch: 'full'
                        }
                    ]
                }
            ],
            {
                preloadingStrategy: NoPreloading,
                scrollPositionRestoration: 'top'
            }
        )
    ],
    providers: [
        ApiService, 
        DashboardService, 
        DataService, 
        ModalService, 
        NumberFormatter, 
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },    
        { provide: ErrorHandler, useClass: SentryErrorHandler },
    ],
    exports: [RouterModule],
    bootstrap: [AppComponent]
})

export class AppModule {

}
