import {Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {ApiService} from '../../utility/services/api.service';
import capitalize from 'lodash-es/capitalize';
import {MixpanelEvents, MixpanelService} from '../../views/shared/services/mixpanel.service';
@Component({
    selector: 'app-intelligence-bot',
    templateUrl: './intelligence-bot.component.html',
    styleUrls: ['./intelligence-bot.component.css']
})
export class IntelligenceBotComponent implements OnInit {
    @Input() isOpen: boolean;
    @Output() onInsightsClose = new EventEmitter<boolean>();
    subHeading: string = 'Choose a category';
    questionCategories: any = [];
    questions: any = {};
    chartColors: any = ['#3598db', '#30c500'];
    @ViewChild('botContentBox', {static: false}) botContentBox: ElementRef;

    lineChartOptions: any;

    constructor(private apiService: ApiService, private trackingService: MixpanelService) {}
    ngOnInit() {
        this.getQuesForIntelligenceBot();
    }

    //get questions for intelligence bot
    getQuesForIntelligenceBot() {
        this.apiService.getData('/v3/intelligence_bot/get_questions', null).subscribe((response) => {
            this.questions = response;
            this.questionCategories = Object.keys(response);
        });
    }

    //change text on category selection
    onCategorySelect(category) {
        this.trackingService.trackEvents(MixpanelEvents.chooseCategory, {category: category});
        this.subHeading = category ? 'Choose a question from ' + category : 'Choose a category';
    }

    //get answer to intelligence bot question
    getAnswer(question) {
        this.botContentBox.nativeElement.scrollTop = 0;
        question['is_loading'] = true;
        let data = {
            id: question.id
        };
        this.apiService.getData('/v3/intelligence_bot/question_response', data).subscribe(
            (response: any) => {
                this.trackingService.trackEvents(MixpanelEvents.chooseQuestion, data);
                question['response_type'] = response.response_type;
                switch (response.response_type) {
                    case 'comparison':
                        question['answer_unit'] = response.response_text['unit'] ? response.response_text['unit'] : null;
                    case 'numeric':
                        let keys = Object.keys(response.response_text);
                        question['answer_titles'] = [];
                        question['answer_values'] = [];
                        keys.forEach((key) => {
                            question['answer_titles'].push(key.split('_').join(' '));
                            question['answer_values'].push(response.response_text[key]);
                        });
                        break;
                    case 'table':
                        question['answer'] = response.response_text;
                        question['answer_columns'] = Object.keys(response.response_text[0]);
                        question['cell_properties'] = [
                            {flex: '1 0 0', 'justify-content': 'left'},
                            {flex: '1 0 0', 'justify-content': 'center'}
                        ];
                        break;
                    case 'line-graph':
                        let graphs = Object.keys(response.response_text);
                        question['answer_chart_data'] = [];
                        question['answer_chart_id'] = 'bot_graph' + question.id;
                        graphs.forEach((key, index) => {
                            let title = key.split('_').slice(0, 2).join(' ');
                            let obj = {
                                c: this.chartColors[index],
                                l: capitalize(title),
                                d: response.response_text[key]
                            };
                            question['answer_chart_data'].push(obj);
                            this.lineChartOptions = {
                                xAxis: {
                                    type: 'category',
                                    data: (Object.values(response.response_text)[0] as []).map((x) => {
                                        return x[0];
                                    })
                                },
                                yAxis: {
                                    type: 'value',
                                    axisPointer: {show: false}
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    show: true,
                                    axisPointer: {type: 'cross'}
                                },
                                lineStyle: {
                                    color: '#2C5E95',
                                    width: '2px'
                                },
                                series: [
                                    {
                                        data: (Object.values(response.response_text)[0] as []).map((x) => {
                                            return x[1];
                                        }),
                                        type: 'line',
                                        smooth: true,
                                        areaStyle: {
                                            color: '#2C5E9512'
                                        },
                                        showSymbol: false
                                    }
                                ]
                            };
                        });
                        break;
                    default:
                        question['answer_value'] = response.response_text;
                }
                question['is_loading'] = false;
            },
            (error) => {
                question['is_loading'] = false;
            }
        );
    }

    closeBot() {
        this.onInsightsClose.next(false);
    }
}
