import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from '../../../../utility/services/api.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatChipsModule} from '@angular/material/chips';
import {MerchantFilterPipe} from '../../../../dashboard/leaderboard/comparison/merchant_filter.pipe';
import {Validators, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {DataService} from '../../../../utility/services/data.service';
import {MatOption} from '@angular/material/core';
import {LoaderComponent} from '../../loader/loader.component';
import {DisableOnApiDirective} from '../../directives/disable-on-api.directive';
import {MixpanelEvents, MixpanelService} from '../../services/mixpanel.service';
@Component({
    selector: 'plans',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonToggleModule,
        FormsModule,
        MatListModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MerchantFilterPipe,
        ReactiveFormsModule,
        MatChipsModule,
        NgxMatSelectSearchModule,
        LoaderComponent,
        DisableOnApiDirective
    ],
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
    @Input() title: string = 'Subscription Plan';
    @Input() hideFreePlan: boolean = false;
    showChecklist: boolean = false;
    duration: any = false;
    plans: any = {years: [], months: []};
    subscription: any;
    merchantList: Array<{id: string | number; location: string}> = [];
    merchantInfo: any;
    public search: FormControl = new FormControl();
    addonClicked: boolean = false;
    @ViewChild('allSelected') private allSelected: MatOption;
    innerWidth:any
    constructor(private apiService: ApiService, private router: Router, private dataService: DataService, private trackingService: MixpanelService) {
        this.innerWidth = window.innerWidth;
    }
    ngOnInit() {
        this.getOutletList();
        this.merchantInfo = this.dataService.getDataFromStore('merchantInfo');
        if (!this.merchantInfo?.last_active_plan?.plan_name && !this.merchantInfo?.subscription_details?.plan_name) {
            this.showChecklist = true;
        }
    }
    // Get plan list
    getPlan() {
        this.apiService.getData('/v3/subscriptions/new', null).subscribe((data: any) => {
            this.subscription = data;
            let discount = [];
            for (let i = 0; i < data?.plans?.length; i++) {
                if (data.plans[i].interval_unit === 'years' || data.plans[i].interval >= 12) {
                    this.plans.years.push(data.plans[i]);
                    discount.push(data.plans[i].discount);
                } else {
                    this.plans.months.push(data.plans[i]);
                }
                if (data.plans[i].code === this.subscription.currently_active_plan) {
                    if (this.subscription?.currently_active_addons?.length && this.subscription.currently_active_addons[0].code === data.plans[i].addons[0].code) {
                        data.plans[i].addons[0].active = true;
                    }
                }
                if (data.plans[i].plan_name === 'Premium') {
                    data.plans[i].outlet_list = this.merchantList;
                    data.plans[i].selected_merchant = new FormControl([], Validators.required);
                    if (data.plans[i].plan_name === this.merchantInfo?.subscription_details?.plan_name) {
                        (data.plans[i].selected_merchant as FormControl).setValue(this.subscription.offline_merchant_ids);
                    }
                }
                if (this.subscription.currently_active_plan && this.subscription.currently_active_plan === data.plans[i].code) {
                    if (data.plans[i].interval_unit === 'years') {
                        this.duration = true;
                    }
                }
            }
            let max = discount.reduce((a, b) => (a = a > b ? a : b), 0);
            this.subscription['max_discount'] = max;
        });
    }
    
    // subscribe now
    subscribePlan(plan: any) {
        if (plan.button_text !== 'Contact us') {
            let params = {};
            params['code'] = plan.code;
            if (plan.plan_name === 'Premium') {
                if (this.merchantInfo.role === 'ChainOwner') {
                    params['offline_merchant_ids'] = plan.selected_merchant.value;
                    //remove '0' value of all option from selected merchant list
                    if (this.allSelected.selected) {
                        params['offline_merchant_ids'].pop();
                    }
                    if (plan.selected_merchant.invalid) {
                        (plan.selected_merchant as FormControl).markAsTouched();
                        return false;
                    }
                } else if (this.merchantInfo.role === 'Merchant' && !this.merchantInfo.chain_id) {
                    params['offline_merchant_ids'] = [this.merchantInfo.id];
                }
            }
            if (plan?.addons?.length && plan.addons[0].active) {
                params['addon_code'] = plan.addons[0].code;
            }
            this.apiService.postData('/v3/subscriptions', params).subscribe((data: any) => {
                this.trackingService.trackEvents(MixpanelEvents.subscribePlan, params);
                window.open(data.url, '_blank');
            });
        } else {
            this.activateFeature(plan);
        }
    }
    // Activate plan
    activateFeature(plan: any) {
        let body = {plan_code: plan.code};
        this.apiService.postData('/v2/notification_to_activate_feature', body).subscribe(
            (data: any) => {
                Swal.fire({
                    title: 'Success',
                    text: data.message,
                    icon: 'success',
                    timer: 4000,
                    timerProgressBar: true
                });
            },
            (error) => {
                Swal.fire({
                    title: 'Failure',
                    text: error.error.message,
                    icon: 'error',
                    timer: 4000,
                    timerProgressBar: true
                });
            }
        );
    }

    changeDuration(event: any) {
        this.duration = event.value;
    }

    getOutletList() {
        this.apiService.getData('/v4/merchants/outlet_list', null).subscribe((response: any) => {
            this.merchantList = response.merchant_list;
            this.getPlan();
        });
    }

    toggleSelectAll(plan: any) {
        if (this.allSelected.selected) {
            (plan.selected_merchant as FormControl).patchValue([...this.merchantList.map(({id}) => id), 0]);
        } else {
            (plan.selected_merchant as FormControl).patchValue([]);
        }
    }
    // deselect and select
    toggleSingleOutlet(all, plan: any) {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
            return false;
        }
        if ((plan.selected_merchant as FormControl).value.length == plan.outlet_list.length) this.allSelected.select();
    }
}
