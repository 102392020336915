<div
  clickOutside
  (clickOutside)="toggleSelectList('outside')"
  class="select-container"
  [ngClass]="{ 'error-invalid': isInvalid }"
>
  <div class="flex select-box tooltip" (click)="toggleSelectList('inside')">
    <app-icon
      class="down-arrow-icon"
      [iconName]="'down-arrow'"
      [iconStyles]="{ fill: '#000000', width: '12px', height: '12px' }"
    ></app-icon>
    <!-- clear icon -->
    <i *ngIf="showClear" class="material-icons" (click)="clearData()">close</i>
    <!-- end clear icon -->
    <span class="floating-label" *ngIf="floatingLabel">{{
      floatingLabel
    }}</span>
    <span class="floating-label optional" *ngIf="optionalLabel">{{
      optionalLabel
    }}</span>
    <div
      class="selected-options"
      *ngIf="selectedValues !== undefined || selectedValues?.length > 0"
    >
      <div *ngIf="multiple">
        <span *ngFor="let selection of selectedValues; let i = index">
          <span *ngIf="i > 0">{{ ', ' }}</span>
          <span *ngIf="propertyNames.name">{{
            propertyNames.name2
              ? selection[propertyNames.name] +
                ', ' +
                selection[propertyNames.name2]
              : selection[propertyNames.name]
          }}</span>
          <span *ngIf="!propertyNames.name">{{ selection }}</span>
        </span>
      </div>
      <div *ngIf="!multiple && !propertyNames?.optionsUpdate">
        <div *ngFor="let option of optionsList">
          <span
            *ngIf="
              selectedValues && selectedValues[propertyNames.id] !== undefined
                ? option[propertyNames.id] === selectedValues[propertyNames.id]
                : option[propertyNames.id] === selectedValues
            "
          >
            {{ option[propertyNames.name] }}
          </span>
        </div>
      </div>
      <div *ngIf="!multiple && propertyNames?.optionsUpdate">
        {{
          selectedValues && selectedValues[propertyNames.name]
            ? selectedValues[propertyNames.name]
            : selectedValues
        }}
      </div>
    </div>
    <span
      *ngIf="
        selectedValues !== undefined && selectedValues?.length > 0 && multiple
      "
      class="tooltip-text bottom"
    >
      <div *ngFor="let selection of selectedValues; let i = index">
        <span *ngIf="propertyNames.name">{{
          propertyNames.name2
            ? selection[propertyNames.name] +
              ', ' +
              selection[propertyNames.name2]
            : selection[propertyNames.name]
        }}</span>
        <span *ngIf="!propertyNames.name">{{ selection }}</span>
      </div>
      <i></i>
    </span>
    <span
      class="placeholder"
      *ngIf="selectedValues === undefined || selectedValues?.length === 0"
    >
      {{ placeholder }}
    </span>
  </div>
  <div
    class="select-list"
    [ngClass]="position ? position : 'bottom'"
    *ngIf="showSelectList"
  >
    <div class="flex select-search" *ngIf="showSearch">
      <input type="text" [(ngModel)]="searchSelect" />
      <i class="material-icons search-icon"> search </i>
    </div>
    <!-- multiple selection -->
    <div *ngIf="multiple">
      <div
        class="select-option"
        *ngFor="
          let option of optionsList
            | filter : searchSelect : propertyToSearchFor;
          let i = index
        "
      >
        <label class="custom-checkbox">
          <span class="content" *ngIf="propertyNames.name">{{
            propertyNames.name2
              ? option[propertyNames.name] + ', ' + option[propertyNames.name2]
              : option[propertyNames.name]
          }}</span>
          <span class="content" *ngIf="!propertyNames.name">{{ option }}</span>
          <input
            type="checkbox"
            [value]="propertyNames.id ? option[propertyNames.id] : option"
            [checked]="option.isChecked"
            (change)="selectOption($event, option, 'multiple')"
          />
          <span class="checkmark"></span>
        </label>
        <div *ngIf="propertyNames?.description" class="select-description">
          {{ option[propertyNames.description] }}
        </div>
      </div>
    </div>
    <!-- single selection -->
    <div *ngIf="!multiple">
      <div
        class="select-option"
        *ngFor="
          let option of optionsList
            | filter : searchSelect : propertyToSearchFor
        "
      >
        <label class="custom-radio-button">
          <span class="content" *ngIf="propertyNames.name">{{
            propertyNames.name2
              ? option[propertyNames.name] + ', ' + option[propertyNames.name2]
              : option[propertyNames.name]
          }}</span>
          <span class="content" *ngIf="!propertyNames.name">{{ option }}</span>
          <input
            type="radio"
            [value]="propertyNames.id ? option[propertyNames.id] : option"
            [checked]="
              propertyNames.id
                ? option[propertyNames.id] === selectedValues
                : option === selectedValues
            "
            name="optionTypes"
            (change)="selectOption($event, option, 'single')"
          />
          <span class="radiomark"></span>
        </label>
        <div *ngIf="propertyNames?.description" class="select-description">
          {{ option[propertyNames.description] }}
        </div>
      </div>
    </div>
    <!-- empty option list -->
    <div class="select-option" *ngIf="optionsList?.length === 0">
      No option available to select
    </div>
  </div>
</div>
