import { Component,Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'home-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-loader.component.html',
  styleUrls: ['./home-loader.component.scss']
})
export class HomeLoaderComponent {
  @Input() message:string;
}
