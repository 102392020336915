import { Component,Input,EventEmitter,Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule} from '@angular/material/icon';
import { Toast } from './toaster.service';
import { ApiService } from '../../../utility/services/api.service';
import { Router } from '@angular/router';
@Component({
    selector: 'toaster',
    standalone: true,
    imports: [CommonModule,MatIconModule],
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
})

export class ToasterComponent {
  constructor(private apiService:ApiService,private router: Router){}
    @Input() toast: Toast;
    @Input() i: number;
    @Output() remove = new EventEmitter<number>();
    icons:any={
      success:'check_circle',
      warning:'report_problem',
      error:'error'
    }
    // close notification mark as read
    close(index:any,toast:any){
      this.removeNotiy(index,toast,false)
    }
    // redirect to template overview
    open(index:number,toast:any){
      this.removeNotiy(index,toast,true)
    }
    // mark as read only from backend
    removeNotiy(index:number,toast:any,type:boolean){
      this.apiService.putData('/v4/notifications/'+toast.id,null).subscribe((respnse:any)=>{
        this.remove.emit(index);
        if(type) this.router.navigateByUrl('/templates');
      })
    }
}
