import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import { NumberFormatter } from '../../../global';
import { MatSort, Sort } from '@angular/material/sort';
@Component({
  selector: 'app-generic-data-table',
  templateUrl: './generic-data-table.component.html',
  styleUrls: ['./generic-data-table.component.css'],
})
export class GenericDataTableComponent implements OnInit, OnChanges {
  page: any = 1;
  limit: number = 10;
  isClickable: boolean = false;
  searchValue: string = '';
  config: any;
  columnHeading: any;
  @Input() sortingColumnArray;
  @Input() show_search: any;
  @Input() count: any;
  @Input() table_title: any;
  @Input() hideTitle: boolean;
  @Input() item_per_page: any;
  @Input() totalRecords: any;
  @Input() featureFilterDetails: any;
  @Input() buttonFilterDetails: any;
  @Input() search_placeholder: string = 'Search here';
  @Input() tableData: any;
  @Input() displayedColumns: any;
  @Input() defaultMessage: any;
  @Input() showFeatureFilter: any;
  @Input() cellProperties: {}[] = [];
  @Input() tableHeadings: any;
  @Input() tableContent: any;
  @Input() featureFilterEvent: any;
  @Input() rowClickEvent: any;
  @Output() onRowClick = new EventEmitter<string>();
  @Output() pageEvent = new EventEmitter<string>();
  @Output() searchEvent = new EventEmitter<any>();
  @Output() sorting = new EventEmitter<any>();
  @Output() onFeatureFilterChange = new EventEmitter<any>();
  @Output() onButtonClick = new EventEmitter<any>();
  constructor(public numberFormatter: NumberFormatter) {}

  ngOnInit() {
    if (!this.tableHeadings) {
      this.tableHeadings = {};
      this.displayedColumns.forEach((column) => {
        let col = column.split('_').join(' ');
        this.tableHeadings[column] = col.charAt(0).toUpperCase() + col.slice(1);
      });
    }
    this.hideTitle = this.hideTitle ? true : false;
    this.isClickable = this.onRowClick.observers.length > 0 ? true : false;
    this.defaultMessage = this.defaultMessage
      ? this.defaultMessage
      : 'No data available for the selected time period.';
  }
  pageChanged(event) {
    setTimeout(() => {
      this.config.currentPage = event;
      this.page = event;
      this.pageEvent.emit(this.page);
    }, 100);
  }

  onClick(data) {
    this.onRowClick.emit(data);
  }

  search(event) {
    // this.searchEvent.next(event);
  }

  ngOnChanges() {
    if (this.totalRecords) {
      this.config = {
        itemsPerPage: this.item_per_page ? this.item_per_page : this.limit,
        currentPage: this.page,
        totalItems: this.totalRecords,
        id: this.table_title,
      };
    } else {
      this.config = {
        itemsPerPage: this.item_per_page ? this.item_per_page : this.limit,
        currentPage: this.page,
        id: this.table_title,
      };
    }
  }
  sortData(sort: Sort) {
    let datapass = {
      column: sort.active,
      order: sort.direction,
      page: this.page,
    };
    this.sorting.emit(datapass);
  }
  isSortingDisabled(columnText: string): boolean {
    if (this.sortingColumnArray) {
      let idx = this.sortingColumnArray.indexOf(columnText);
      return idx > -1 ? false : true;
    } else {
      return true;
    }
  }

  onChange(event, type) {
    this.onFeatureFilterChange.emit(event.value);
  }

  onBtnClick(event) {
    this.onButtonClick.emit(event);
  }
}
