import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-congrats-popup',
  templateUrl: './congrats-popup.component.html',
  styleUrls: ['./congrats-popup.component.css'],
})
export class CongratsPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CongratsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {}
  confirm() {
    this.dialogRef.close();
  }
}
