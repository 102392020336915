<div
  clickOutside
  (clickOutside)="toggleSelectList('outside')"
  class="select-container"
  [ngClass]="{ 'error-invalid': isInvalid }"
>
  <div class="flex select-box tooltip">
    <app-icon
      class="down-arrow-icon"
      [iconName]="'down-arrow'"
      [iconStyles]="{ fill: '#000000', width: '12px', height: '12px' }"
      (click)="toggleSelectList('toggle')"
    ></app-icon>
    <span class="floating-label optional" *ngIf="floatingLabel">{{
      floatingLabel
    }}</span>
    <span class="floating-label optional" *ngIf="optionalLabel">{{
      optionalLabel
    }}</span>
    <input
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="this[input_key]"
      (click)="toggleSelectList('inside')"
      readonly
    />
    <span *ngIf="selections?.length" class="tooltip-text bottom">
      {{ selections }}
    </span>
  </div>
  <div class="select-list" [ngClass]="position" *ngIf="showSelectList">
    <div>
      <div
        class="select-option flex"
        *ngIf="newButton?.show"
        (click)="selectOption('create new')"
      >
        {{ newButton.text }}
        <i class="material-icons">add_circle</i>
      </div>
      <!-- <div *ngIf="selectAll" class="select-option select_all" >
          <label class="custom-checkbox">
            <span class="content">Select all</span>
            <input type="checkbox"
              (change)="selectAllFun($event)">
            <span class="checkmark"></span>
          </label>
      </div> -->
      <div
        class="select-option"
        *ngFor="
          let option of optionsList
            | filter : search_select : propertyToSearchFor;
          let i = index
        "
      >
        <label class="custom-checkbox">
          <span class="content" *ngIf="propertyNames.name">{{
            propertyNames.name2
              ? option[propertyNames.name] + ', ' + option[propertyNames.name2]
              : option[propertyNames.name]
          }}</span>
          <span class="content" *ngIf="!propertyNames.name">{{ option }}</span>
          <input
            type="checkbox"
            [value]="propertyNames.id ? option[propertyNames.id] : option"
            [checked]="option.isChecked"
            (change)="selectOption(option)"
          />
          <span class="checkmark"></span>
        </label>
        <div *ngIf="propertyNames?.description" class="select-description">
          {{ option[propertyNames.description] }}
        </div>
      </div>
    </div>
    <!-- empty option list -->
    <div class="select-option" *ngIf="optionsList?.length === 0">
      No option available to select
    </div>
  </div>
</div>
