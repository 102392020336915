<div id="commonDialog">
  <ng-container *ngTemplateOutlet="template"></ng-container>
  <div *ngIf="actionBar" class="action__container">
    <button
            class="btn bth--thrive--border"
            *ngIf="noButtonText"
            [mat-dialog-close]="false">
      {{ noButtonText }}
    </button>
    <button
            class="btn-thrive_primary"
            *ngIf="yesButtonText"
            [mat-dialog-close]="yesButtonText">
      {{ yesButtonText }}
    </button>
    <button
            class="btn-thrive_primary"
            *ngIf="yesButtonText2"
            [mat-dialog-close]="yesButtonText2">
      {{ yesButtonText2 }}
    </button>
  </div>
</div>
