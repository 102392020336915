import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataService } from '../utility/services/data.service';
import { CongratsPopupComponent } from '../dashboard/congrats-popup/congrats-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private url: string = environment.RAILS_API;
  constructor(
    public http: HttpClient,
    private dataService: DataService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  dialogRef: any;
  origin_url: any = location.origin;
  origin: any = {
    'http://localhost:4200': 'https://orderdashboard.hashtagloyalty.net',
    'https://dashboard.hashtagloyalty.net': 'https://orderdashboard.hashtagloyalty.net',
    'https://dashboard.hashtagloyalty.com': 'https://staff.thrivenow.in',
  };
  
  onReloadUpdateConfig(popup: boolean) {
    this.http.get(this.url + '/v2/auth').subscribe((success: any) => {
        this.dataService.setDataInStore('merchantInfo', success.merchant);
        this.dataService.setDataInStore('logo', success.logo_url);
        this.dataService.setData('localStorageUpdated', true);
        if (popup) {
          this.router.navigate(['/overview'], {
            queryParamsHandling: 'preserve',
          });
          // congrats popup
          this.dialogRef = this.dialog.open(CongratsPopupComponent, {
            width: '484px',
            maxHeight: '422px',
            data: {
              title: 'Congrats!',
              content: 'Here’s your new store link',
              link: success.merchant.thrive_url,
              button_text: 'Great, thanks!',
            },
            disableClose: true,
          });
        }
      }
    );
  }
}
