export const menuItems: any[] = [
    {icon: 'home', link: '/overview', name: 'Home', display: true},
    {
        icon: 'restaurant',
        name: 'Online ordering',
        display: true,
        show_drop_down: false,
        sub_menu: [
            {link: '/thrive-metrics', name: 'Overview', display: true},
            {
                link: '/item-availability/categories',
                name: 'Item availability',
                display: true
            },
            {
                link: '/menu-management/categories',
                name: 'Menu editor',
                display: true
            },
            {link: '/item-charges', name: 'Charges', display: true},
            {link: '/offers', name: 'Offers', display: true},
            {link: '/brand', name: 'Brands', display: false},
            {link: '/banners', name: 'Banners', display: true},
            {link: '/past-order', name: 'Past orders', display: true}
        ]
    },
    {icon: 'group', link: '/customers', name: 'Customers', display: true},
    {
        icon: 'leaderboard',
        link: '/leaderboard',
        name: 'Leaderboard',
        display: false
    },
    {
        icon: 'area_chart',
        link: '/business-intelligence',
        name: 'Sales intelligence',
        display: true
    },
    {
        icon: 'campaign',
        name: 'Marketing',
        display: true,
        show_drop_down: false,
        sub_menu: [
            {link: '/templates', name: 'Templates', display: true},
            {link: '/campaigns', name: 'Campaigns', display: true},
            {link: '/auto-engage', name: 'Auto engage', display: true},
            {link: '/referrals', name: 'Referrals', display: true},
            // {
            //     link: '/facebook-ad',
            //     name: 'Facebook Ads',
            //     beta: true,
            //     display: true,
            // },
            {link: '/online-presence', name: 'Online Presence', display: false}
        ]
    },
    {icon: 'loyalty', link: '/loyalty', name: 'Loyalty', display: true},
    {icon: 'feedback', link: '/feedback', name: 'Feedback', display: true},
    {
        icon: 'reporting',
        link: '/generate-report',
        name: 'Reporting',
        display: true
    },
    {icon: 'settings', link: '/settings', name: 'Settings', display: true},
    {icon: 'logout', name: 'Logout', display: true, sub_menu: []}
];
