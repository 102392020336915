import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface commonDialogParams {
  template: string | TemplateRef<any>;
  actionBar: boolean;
  yesButtonText: string;
  yesButtonText2?: string;
  noButtonText?: string;
}

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['../css/style.css', './common-dialog.component.scss'],
})
export class CommonDialogComponent implements OnInit {
  template: any = '';
  actionBar = true;
  yesButtonText = 'Yes';
  yesButtonText2 = '';
  noButtonText = 'No';

  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: commonDialogParams
  ) {}

  ngOnInit() {
    this.template = this.data.template;
    this.actionBar = this.data.actionBar;
    this.yesButtonText = this.data.yesButtonText;
    this.yesButtonText2 = this.data.yesButtonText2;
    this.noButtonText = this.data.noButtonText;
  }
}
