import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../utility/services/data.service';
import * as mixpanel from 'mixpanel-browser';
import clevertap from 'clevertap-web-sdk';

export enum MixpanelEvents {
    login = 'Login',
    logout = 'Logout',
    pageView = 'Page View',
    signUp = 'Sign Up',
    clickLink = 'Click Link',
    templateModeSelection = 'Mode Selection for template creation',
    headerSelection = 'Header Selection',
    buttonSelection = 'Button Selection',
    SMSTemplateCreation = 'SMS Template Creation',
    emailTemplateCreation = 'Email Template Creation',
    viewTemplate = 'View Template',
    campaignModeSelection = 'Mode Selection for Campaign creation',
    campaignType = 'Campaign Type Selection',
    audienceSelection = 'Audience Selection',
    contentSelection = 'Content Selection',
    campaignSendSchedule = 'Send/Schedule campaign',
    rewardSelection = 'Reward type Campaign',
    viewCampaign = 'View Campaign',
    editCampaign = 'Edit Campaign',
    setupAutoEngage = 'Setup Auto Engage',
    campaignTypeAutoEngage = 'Campaign Type Selection',
    contentSelectionAutoEngage = 'Content Selection',
    rewardSelectionAutoEngage = 'Reward type Campaign',
    viewAutoEngage = 'View Auto-engage Campaigns',
    editAbandonedCart = 'Edit Abandoned Cart',
    createReferral = 'Create Referral',
    createLoyalty = 'Create Loyalty Program',
    createFeedback = 'Create feedback',
    generateReport = 'Generate Report',
    takeATour = 'Click Link Take a Tour',
    toggleItem = 'Toggle on or off on item availability',
    searchItem = 'Search on item availability',
    addNewCategory = 'Add New Category',
    addNewSubCategory = 'Add New Sub-Category',
    addNewItem = 'Add New Item',
    createNewVariation = 'Create new Variation',
    createNewAddOns = 'Create new Add-ons',
    addCharge = 'Add Charge',
    editCharge = 'Edit Charge',
    addOffer = 'Add Offer',
    editOffer = 'Edit Offer',
    addEditBanner = 'Add/Edit Banner Image',
    viewPastOrders = 'View Past orders',
    uploadCustomers = 'Upload customers',
    viewLeaderBoard = 'View Leaderboard',

    // 2nd iteration

    // Settings Page
    downloadFormat = 'Download Format',
    uploadSettings = 'Upload Under Settings',
    imageSettings = 'Update Image settings',
    updateRestaurantInfo = 'Update restaurant information',
    updateGST = 'Update GST',
    updateFSSAI = 'Update FSSAI in Settings',
    updateSocialMedia = 'Update social media',
    updateBankDetails = 'Update bank details in Settings',
    updateOrderingBasics = 'Update ordering basics',
    updateOrderDashboard = 'Update order dashboard',
    updateCommunicationDetails = 'Update comms details',
    updateOpenHours = 'Update open hours',
    uploadTables = 'Upload tables',
    downloadTableQRCode = 'Download Table QR',
    addTableDetails = 'Add table details',
    updateGTag = 'Update Gtag',
    updateFBPixel = 'Update fb pixel',
    updatePaymentChannel = 'Update payment channel',
    updateDelivery = 'Update delivery',
    insightsPage = 'Insights Page',
    invoiceHistoryDownload = 'Download Invoice History',
    themeColor = 'Custom Theme Color',

    // Sign Up Page
    platformPricingPage = 'Platform and pricing Page',
    restaurantInfoPage = 'Restaurant Info Page',
    addressPage = 'Address Page',
    openHoursPage = 'Open hours Page',
    settingsOnboardingPage = 'Settings Onboarding Page',

    //Filters
    filterOnFeedback = 'Filter on feedback',
    filterOnReferral = 'Filter on Referral',
    filterOnSalesIntelligence = 'Filter on Sales Intelligence',
    filterOnCustomers = 'Filter on Customers Overview',
    filterOnPastOrders = 'Filter on Past Orders',

    // Customers Page
    customersTabSwitch = 'Customers Tabs',
    customersSegmentation = 'Customers Segmentation change',
    customerTagSelect = 'Customers Tag change',
    customerProfile = 'Customers Profile navigation',
    customerSettingsSave = 'Customers Settings save',
    customerNoteCreate = 'Cusomers Note Create',
    customerUpdateUser = 'Customer Info Update',
    customerAssignTag = 'Customer Assign Tag',
    customerLoyaltyPoints = 'Customer Loyalty Points',

    //Others
    outletSwitch = 'Switch Outlet',
    otpVerification = 'OTP Verification Status',
    GSTInfo = 'GST no. help',
    autoEngageVideo = 'AutoEngage Video Viewed',
    helpPopUp = 'Help Pop Up',
    subscribePlan = 'Subscribe to Plan',

    //Outlet Manager
    addOutlet = 'Add Outlet From Outlet Manager',
    toggleOutlet = 'Toggle Outlet From Outlet Manager',

    //Insights
    openInsights = 'Open Insights Pop Up',
    chooseCategory = 'Choose Category Insights',
    chooseQuestion = 'Choose Question Insights',

    // Loyalty
    disableLoyalty = 'Disable Loyalty',

    // //campaign report
    downloadCampaignReport = 'Download Campaign report',
    downloadCustomerReport = 'Download customer report',
    backbutton = 'Back button',

    //dashboard qr code
    qrViewed = 'QR Popup Viewed',
    qrShared = 'QR Shared',
    qrDownloaded = 'QR Downloaded',

    //merchant checklist
    checklistClicked = 'Checklist clicked',
    checklistMarkedComplete = 'Checklist mark complete',

    //order db banner
    orderDBbannerClicked = 'Order DB Banner Clicked',
    playStoreLinkClicked = 'PlayStore Link Clicked',

    // TMS on boarding
    enableFreeTrialClicked = 'Enable free trial clicked',
    sendWACampaignClicked = 'Send WA campaign clicked',
    createNewCampaignClicked = 'Create new campaign clicked',
    createNewTemplateClicked = 'Create new template clicked',
    freeTrialEnableViaToggle = 'Free trial enable via toggle',
}

export interface mixpanelLogin {
    name: string;
    merchant_id: string;
    user_id: string;
    merchant_location: string;
    merchant_chain_id: string;
    source: string;
}

export interface mixpanelLogout {
    user_id: string;
    source: string;
}

export interface mixPanelPageView {
    page_url: string;
    source_url: string;
}

@Injectable({
    providedIn: 'root'
})
export class MixpanelService implements OnDestroy {
    merchantSubscription$: any = null;
    merchant_details: any = {
        merchant_chain_id: null,
        merchant_id: null
    };

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private dataService: DataService) {
        if (isPlatformBrowser(this.platformId)) {
            mixpanel.init(environment.MIXPANEL_ID, { debug: true });
            this.initialise();
        }
    }

    initialise() {
        this.merchantSubscription$ = this.dataService.refresh_variable.subscribe((merchant_login: any) => {
            if (merchant_login) {
                let merchant = this.dataService.getDataFromStore('merchantInfo');
                if (merchant) {
                    this.merchant_details.merchant_chain_id = merchant.chain_id ? merchant.chain_id : null;
                    this.merchant_details.merchant_id = merchant.id;
                    this.setSuperProperties(this.merchant_details);
                }
            }
        });
    }

    setSuperProperties(super_prop_obj: any) {
        mixpanel.register(super_prop_obj);
    }

    getDistinctId() {
        return mixpanel.get_distinct_id();
    }

    identifyUser(merchant_id: string) {
        mixpanel.identify(merchant_id);
    }

    trackEvents(name: string, event_obj?: any) {
        event_obj = event_obj ? event_obj : {};
        mixpanel.track(name, event_obj);
        for (let obj in event_obj) {
            if (typeof event_obj[obj] !== 'string') {
                event_obj[obj] = JSON.stringify(event_obj[obj]);
            }
        }
        let merchant = this.dataService.getDataFromStore('merchant_user');
        let merchantInfo = this.dataService.getDataFromStore('merchantInfo');
        clevertap.onUserLogin.push({
            Site: {
                Name: merchant.fname,
                Email: merchant.email,
                Mobile: merchantInfo.whatsapp_contact,
                "MSG-email": true,                // Enable email notifications
                "MSG-push": true,                  // Enable push notifications
                "MSG-sms": true,                   // Enable sms notifications
                "MSG-whatsapp": true,              // Enable WhatsApp notifications
            }
        });
        clevertap.event.push(name, event_obj);
    }

    // trackLinks(id:string,source:string){
    //   mixpanel.track_links(id,MixpanelEvents.click_link,{source:source});
    // }

    ngOnDestroy() {
        if (this.merchantSubscription$) {
            this.merchantSubscription$.unsubscribe();
        }
    }
}
