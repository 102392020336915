import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-horizontal-steppers',
  templateUrl: './horizontal-steppers.component.html',
  styleUrls: ['./horizontal-steppers.component.css'],
})
export class HorizontalSteppersComponent implements OnInit {
  @Input() stepperValue: any;
  @Input() activeStep: string;
  @Input() currentActive: string;
  @Output() onSelection = new EventEmitter<string>();
  constructor() {}
  ngOnInit() {}
  selectStepper(stepper: any) {
    this.onSelection.next(stepper);
  }
}
