import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from '../../utility/services/api.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-zero-state',
  templateUrl: './zero-state.component.html',
  styleUrls: ['./zero-state.component.scss'],
})
export class ZeroStateComponent implements OnInit {
  constructor( private apiService: ApiService) {}
  @Input() title:string = "Upgrade your plan";
  duration: any = false;
  plans: any = { years: [], months: [] };
  subscription: any;
  ngOnInit() {
    this.getPlan();
  }
  getPlan() {
    this.apiService
      .getData('/v3/subscriptions/new', null)
      .subscribe((data: any) => {
        this.subscription = data;
        let discount = [];
        let flag: number = 0;
        for (let i = 0; i < data.plans.length; i++) {
          // data.plans[i].interval = 10;
          if (
            data.plans[i].interval_unit === 'years' ||
            data.plans[i].interval >= 12
          ) {
            this.plans.years.push(data.plans[i]);
            discount.push(data.plans[i].discount);
          } else {
            this.plans.months.push(data.plans[i]);
          }
          if (
            this.subscription.currently_active_plan &&
            this.subscription.currently_active_plan === data.plans[i].code
          ) {
            if (data.plans[i].interval_unit === 'years') {
              this.duration = true;
            }
          }
        }
        let max = discount.reduce((a, b) => (a = a > b ? a : b), 0);
        this.subscription['max_discount'] = max;
      });
  }
  createPlan(data: any) {
    if (this.subscription.automated_subscripton_allowed) {
      let params = { code: data.code };
      this.apiService
        .postData('/v3/subscriptions', params)
        .subscribe((data: any) => {
          window.open(data.url, '_blank');
        });
    } else {
      this.activateFeature(data);
    }
  }
  activateFeature(data: any) {
    let body = { plan_code: data.code };
    this.apiService
      .postData('/v2/notification_to_activate_feature', body)
      .subscribe(
        (data: any) => {
          Swal.fire({
            title: 'Success', 
            text: data.message, 
            icon: 'success', 
            timer: 4000,
            timerProgressBar: true
          })
        },
        (error) => {
          Swal.fire({
            title: 'Failure', 
            text: error.error.message, 
            icon: 'error', 
            timer: 4000,
            timerProgressBar: true
          })
        }
      );
  }
  changeDuration(value: any) {
    this.duration = value.target.checked;
  }
}
