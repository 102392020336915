import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DataService } from '../../../utility/services/data.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'wallet-header-info',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
  templateUrl: './wallet-header-info.component.html',
  styleUrls: ['./wallet-header-info.component.scss'],
})
export class WalletHeaderInfoComponent implements OnInit {
  wallet_balance: number = 0;
  @Output() toggleMenuEvent = new EventEmitter<void>();

  constructor(public dataService: DataService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.dataService.wallet_balance$.subscribe((balance: number) => {
      this.wallet_balance = balance;
      this.cd.detectChanges();
    });
  }

  closeSidebar() {
    this.toggleMenuEvent.emit();
  }
}
