// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  RAILS_API: 'https://dashboardapi.hashtagloyalty.net',
  version: 'v3',
  MIXPANEL_ID:'16c249f728ec585fffe8b967d55ec3aa',
  GROWTH_BOOK_KEY:'staging_jC1F2rgxbuUmsho3RBKlGub5274v2NFvefSuCphZ8Y',
  ENABLE_FEATURE_FLAG_DEV_MODE:true,
  CLEVER_TAP:'9WW-Z7Z-R96Z',
  SENTRY_ENV:'development'
};