<mat-toolbar id="appToolbar" [ngClass]="{ expanded: isExpanded }">
    <div class="desktop-only">
        <outlet-selector></outlet-selector>
        <div class="header-right">
            <wallet-header-info></wallet-header-info>
            <button class="btn-thrive_icon" (click)="openChat()" [matTooltip]="'Contact Support'" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
                <img mat-list-icon src="assets/img/sidebar/whatsapp-desktop-icon.svg" />
            </button>
            <button (click)="openHelpPopup()" class="btn-thrive_icon" [matTooltip]="'Help'" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
                <mat-icon>question_mark</mat-icon>
            </button>
            <ng-container *ngIf="merchant?.role !== 'ChainOwner'">
                <button mat-button class="btn-thrive_icon header-menu" [disableRipple]="true" [matMenuTriggerFor]="aboveMenu">
                    <mat-icon class="header-settings">more_vert</mat-icon>
                </button>
                <mat-menu #aboveMenu="matMenu" yPosition="above">
                    <!-- <button (click)="openWhatIsNew()" mat-menu-item class="header-menu-btn">
            <img mat-list-icon src="assets/img/sidebar/whatsnew.svg" />
            <span>What’s New?</span>
          </button> -->
                    <a href="https://thrivenow.zohodesk.com/portal/en/home" target="_blank" mat-menu-item class="header-menu-btn">
                        <img mat-list-icon src="assets/img/sidebar/knowledgebase.svg" />
                        <span>Knowledge Base</span>
                    </a>
                    <button *ngIf="merchant?.is_loyalty_model && merchant?.role != 'ChainOwner'" (click)="topNavigation('marketing-material')" mat-menu-item class="header-menu-btn">
                        <img mat-list-icon src="assets/img/sidebar/marketingmaterial.svg" />
                        <span>Marketing Material</span>
                    </button>
                    <!-- <button *ngIf="merchant?.role !== 'ChainOwner'" (click)="topNavigation('transactions')" mat-menu-item>Upload Transactions</button> -->
                </mat-menu>
            </ng-container>
        </div>
    </div>
    <div class="mobile-only">
        <button (click)="toggleMenu()" class="hamburger">
            <mat-icon class="menu-hamburger">menu</mat-icon>
        </button>
        <img class="thrive_logo" src="../../../assets/img/logo.svg" />
        <button (click)="openHelpPopup()" class="btn-thrive_icon" [matTooltip]="'Help'" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
            <mat-icon>question_mark</mat-icon>
        </button>
    </div>
</mat-toolbar>
