import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFilter',
})
export class UsersFilterPipe implements PipeTransform {
  output: any;
  transform(users: any, user: any): any[] {
    this.output = [];
    if (user !== undefined && users.length) {
      if (/^\d+$/.test(user)) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].mobile.includes(user)) {
            this.output.push(users[i]);
          }
        }
      } else {
        for (let i = 0; i < users.length; i++) {
          if (
            users[i].name &&
            users[i].name.toLowerCase().includes(user.toLowerCase())
          ) {
            this.output.push(users[i]);
          }
        }
      }
    } else {
      this.output = users;
    }
    // // this.pagedData(this.output, offset);
    return this.output;
  }
}
