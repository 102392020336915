<div class="toast toast-{{toast.type}}" [style.top.px]="i*120">
    <mat-icon class="close" (click)="close(i,toast)">close</mat-icon>  
    <div class="noty-flex" (click)="open(i,toast)">
        <div><mat-icon class="icon__size">{{icons[toast.type]}}</mat-icon></div>
        <div>
            <h4>{{toast.title}}</h4>
            <div [innerHTML]="toast.body"></div>
            <div class="see-details">
                <a class="link">See details</a>
            </div>
        </div>
    </div>  
</div>