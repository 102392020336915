import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentencecase',
  standalone: true
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: any, type: any): any[] {
    switch (type) {
      case 'remove_':
        value = value.replace(/_/g, ' ');
        break;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
