import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../utility/services/data.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public dataService: DataService,
    private dialog: MatDialog
  ) {}
  urlsToIgnore:Array<string>=['/login','/sign-up'];
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.dataService.getDataFromStore('merchant_user_auth_token')) {
      return true;
    } else {
      this.dialog.closeAll();
      //check if user wanted to route to a url other than sign-up/login
      let routingDetsials:Object=this.urlsToIgnore.indexOf(state.url)>-1?{}:{
        queryParams: {
          redirectTo:state.url
        }
      };
      this.router.navigate(['login'],routingDetsials);
      return false;
    }
  }
}
