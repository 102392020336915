<div id="subscriptionPlan">
  <div class="title-row">
    <h3>{{title}}</h3>
    <div class="group-button">
      <mat-button-toggle-group class="pretitle" [(ngModel)]="duration">
        <mat-button-toggle [value]="false">Monthly</mat-button-toggle>
        <mat-button-toggle [value]="true">Yearly</mat-button-toggle>
      </mat-button-toggle-group>
      <span class="save ml-10" *ngIf="subscription?.max_discount > 0">
        <i class="pretitle">(Save {{ subscription?.max_discount }}%)</i>
      </span>
    </div>
  </div>

  <div class="plan_feature text-center" *ngIf="subscription?.currently_active_plan">
    You are on the <b>{{ subscription?.currently_active_plan }}</b> plan
  </div>
  <!-- <div class="durations">
    <span class="plan_feature" [ngClass]="{ activetext: duration == false }"
      >Bill monthly</span
    >
    <label class="switch">
      <input
        type="checkbox"
        [(ngModel)]="duration"
        (click)="changeDuration($event)"
      />
      <span class="slideron round"></span>
    </label>
    <span class="plan_feature" [ngClass]="{ activetext: duration == true }"
      >Bill yearly<i class="save" *ngIf="subscription?.max_discount > 0"
        >(Save {{ subscription?.max_discount }}%)</i
      ></span
    >
  </div> -->
  <div class="plan-list" *ngIf="plans[duration ? 'years' : 'months'].length">
    <div class="plan-card" [ngClass]="{ 'disabled': !plan.allowed }" *ngFor="let plan of plans[duration ? 'years' : 'months']">
      <div class="subtitle">
        {{ plan.name }}
      </div>

      <div class="plan-type">
        <div *ngIf="plan.discount > 0" class="plan-duration">
            <span *ngIf="plan.original_price_per_month !== 0">&#x20b9;</span>
            {{ plan.original_price_per_month > 0 ? plan.original_price_per_month : 'Free'}}
        </div>

        <div class="mb-10">
            <span  class="basetitle">
              <span *ngIf="plan.price_per_month !== 0">&#x20b9;</span>
              {{plan.price_per_month > 0 ? plan.price_per_month : 'Free'}}
            </span>
            <span class="plan-duration" *ngIf="plan.price_per_month !== 0">
              {{ subscription.number_of_outlets > 1 ? '/month per outlet': '/month'}} 
            </span>
        </div>

      </div>

      <div class="save-money pretitle">
        <span *ngIf="plan.savings">Save {{ plan.savings }}%</span>
      </div>

      <div class="btn-thrive_primary" [ngStyle]="{cursor: subscription?.automated_subscripton_allowed ? 'pointer' : 'auto' }" (click)="createPlan(plan)">
        {{ subscription?.automated_subscripton_allowed ? 'SUBSCRIBE' : 'CONTACT US'}}
      </div>

      <div class="pretitle gray-text">
        Credit card required
      </div>

      <ul *ngFor="let features of plan.store_markup_description">
        <li>{{ features }}</li>
      </ul>

    </div>
  </div>

  <app-skeleton-loader
    *ngIf="!plans[duration ? 'years' : 'months'].length"
    [type]="'box'"
    [details]="{ styles: { height: '450px' } }"
  ></app-skeleton-loader>

  <p class="mt-20">
    If you have more than 3 outlets, and you’d like to activate any one of the
    above plans, please reach out to us at
    <span class="link">sales@hashtagloyalty.com</span>
  </p>

</div>
