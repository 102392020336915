import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GrowthBook } from "@growthbook/growthbook";
import { MixpanelService } from './mixpanel.service';
import { environment } from '../../../../environments/environment';
// import { DataService } from '../../../utility/services/data.service';
import * as mixpanel from 'mixpanel-browser';

export const growth_book = new GrowthBook({
  trackingCallback: (experiment, result) => {
    mixpanel.track("$experiment_started", {
      "Experiment name": experiment.key,
      "Variant name": result.variationId,
      $source: "growthbook",
    });
  },
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: environment.ENABLE_FEATURE_FLAG_DEV_MODE,
});

export enum featureFlagKey {
  templateSuccessCta = 'template_success_cta',
}

@Injectable({
  providedIn: 'root'
})
export class FeaturesFlagService implements OnDestroy {
  growthBookSubscription$:any;
  growthApiKey:string = environment.GROWTH_BOOK_KEY;
  merchantInfo:any

  constructor(private http: HttpClient,
              //  private dataService: DataService,
               private mixpanelService : MixpanelService) {}
  
  getFeatureFlags(){
    this.http.get('https://cdn.growthbook.io/api/features/'+this.growthApiKey).subscribe((response:any)=>{
      growth_book.setFeatures(response.features);
    });
  }

  setAttributes(merchant_details:any){
    
    this.merchantInfo = merchant_details;
    // this.loggedInSubscription$=this.dataService.isLocalStorageUpdated.subscribe((status:any) =>{
      let user_attributes:any={
        id:this.mixpanelService.getDistinctId(),
        loggedIn:true,
        city:this.merchantInfo.city,//update by mixpamnel
        browser:navigator.userAgent,
        url:location.href,
        chainId:this.merchantInfo.chain_id ? this.merchantInfo.chain_id : '',
        merchantId:this.merchantInfo.id
      };
      growth_book.setAttributes(user_attributes);
    // });
  }
  ngOnDestroy(): void {
    this.growthBookSubscription$.unsubscribe();  
    // this.loggedInSubscription$.unsubscribe();   
  }
}