import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {notShowForChainOwner, notShowForNonloyaltyCustomers} from '../global';
import {DataService} from './utility/services/data.service';
import {ApiService} from './utility/services/api.service';
import {MixpanelEvents, MixpanelService, mixPanelPageView} from './views/shared/services/mixpanel.service';
import {FeaturesFlagService} from './views/shared/services/features-flag.service';
import {WindowRef} from './utility/services/window.service';
import {getZohoKBArticle} from './views/features/navigation-header/zoho-hc-asap.model';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  routerSubscription$: any;
  loading: boolean = false;
  current_url:any;
  previous_url:any;
  stepToUrl: any = {
    'Account': 'merchant-agreement',
    'Consent Contract':'profile',
    'Store Profile':'address',
    'Address': 'opening-hours',
    'Opening Hours': 'store-setting',
    'Store Settings': 'store-setting'
  };

    constructor(public dataService: DataService, private router: Router, private apiService: ApiService, private mixpanelService: MixpanelService, private featuresFlagService: FeaturesFlagService, private winRef: WindowRef) {}

    ngOnInit() {
        //crash issue resolved 
        document.addEventListener('DOMNodeInserted', function () {
            const elements = document.querySelectorAll('[aria-owns]');
            elements.forEach(element => {
              element.removeAttribute('aria-owns');
            });
        });

        if (this.dataService.getDataFromStore('merchant_user_auth_token')) {
            let merchantInfo = this.dataService.getDataFromStore('merchantInfo');
            if (merchantInfo && merchantInfo.role === 'Lead') {
                this.apiService.getData('/v3/stages', null).subscribe((data: any) => {
                    if (data && data.current_step) {
                        
                        this.router.navigate(['thrive-step-form/' + this.stepToUrl[data.current_step]]);
                    } else {
                        this.router.navigate(['thrive-step-form/account']);
                    }
                });
            }
        }

        //sync tabs
        this.winRef.nativeWindow.addEventListener('storage', (e) => {
            if (e.key === 'merchantInfo' || e.key === null) {
                this.winRef.nativeWindow.location.reload();
            }
        });

        this.routerSubscription$ = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previous_url = this.current_url;
                this.current_url = event.url;
                let merchantInfo = this.dataService.getDataFromStore('merchantInfo');
                let isChainOwner = merchantInfo && merchantInfo.role === 'ChainOwner' ? true : false;
                switch (true) {
                    case isChainOwner && notShowForChainOwner.indexOf(event.url) > -1:
                    case merchantInfo && !merchantInfo.is_loyalty_model && notShowForNonloyaltyCustomers.indexOf(event.url) > -1:
                        this.router.navigate(['overview']);
                        break;
                }
                this.mixpanelService.trackEvents(MixpanelEvents.pageView, {page_url: event.urlAfterRedirects, source_url: this.previous_url} as mixPanelPageView);
                this.featuresFlagService.getFeatureFlags();
                //ZOHO Knowledge Base
                if (this.winRef.nativeWindow.ZohoHCAsap?.Actions?.GetSettings()?.isAppOpened) {
                    let zohoKbArticleObj = getZohoKBArticle(event.url, event.url.split('/')[1]);
                    if (zohoKbArticleObj.categoryId) {
                        this.winRef.nativeWindow.ZohoHCAsap.kbArticles.list({categoryId: zohoKbArticleObj.categoryId});
                    } else {
                        this.winRef.nativeWindow.ZohoHCAsap.kbCategories();
                    }
                    if (zohoKbArticleObj.articleId) {
                        this.winRef.nativeWindow.ZohoHCAsap.kbArticles.view({articleId: zohoKbArticleObj.articleId});
                    }
                }
                this.mixpanelService.identifyUser(merchantInfo.id);
            }
        });
    }

    ngOnDestroy() {
        // when the component get's destroyed, unsubscribe all the subscriptions
        this.routerSubscription$.unsubscribe();
    }
    
}
