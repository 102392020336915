<div class="table">
    <div class="flex table__heading" >
        <div [class]="header.value.column_width" *ngFor="let header of table_header | keyvalue:returnZero"> {{header.value.name}}</div>
    </div>
    <div class="table__scroll" *ngIf="table_data.length > 0">
        <div *ngFor="let data of table_data | paginate:config; let last = last;">
            <div class="flex table__content hide__in__mobile" [ngClass]="{'cursur__pointer':clickable_row}" (click)="view(data,'desktop')">
                <div  *ngFor="let header of table_header | keyvalue:returnZero;let i = index; let end = last;"  class="{{header.value.column_width}}">
                    <div class="flex align--center" *ngIf="header.key === 'status'">
                        <span class="{{data[header.key] | lowercase}}  {{header.key}} text-center"> 
                            {{data[header.key]  | titlecase}}
                        </span>
                        <app-icon *ngIf="data[header.key] === 'Draft' || data[header.key] === 'Scheduled'" matTooltip="Delete" [iconName]="'trash'" class="icon__edit" [iconStyles]="{'cursor':'pointer','width':'16px','height':'16px'}" (click)="delete(data)"></app-icon>
                        <app-icon *ngIf="data[header.key] === 'Draft' || data[header.key] === 'Scheduled'" matTooltip="Edit" [iconName]="'edit-pen'" class="icon__edit" [iconStyles]="{'cursor':'pointer','width':'16px','height':'16px'}" (click)="edit(data)"></app-icon>
                        <div *ngIf="data[header.key] === 'paused' || data[header.key] === 'disabled'" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span class="tooltip">
                                <mat-icon class="icon-info">info</mat-icon>
                                <span *ngIf="data[header.key] === 'disabled'" class="tooltip-text tooltip-text-info left">
                                    This template is disabled as it has been paused multiple times.
                                  <i></i>
                                </span>
                                <span *ngIf="data[header.key] === 'paused'" class="tooltip-text tooltip-text-info left">
                                    This template has been paused, templates with this status cannot be sent to customers. Please come back and check again in some time.
                                  <i></i>
                                </span>
                              </span>
                        </div>
                    </div>
                    <div  *ngIf="header.key !== 'status'">
                        {{data[header.key]}}
                    </div>
                    <div class="flex gap--8 mobile__width" *ngIf="header.value?.inner_key?.length">
                        <div *ngFor="let in_val of header.value?.inner_key; let j = index;">
                            <span class="info__bar--sm" [ngClass]="{'color--grey':j==1}">{{data[in_val]}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__in__mobile" [ngClass]="{'cursur__pointer':clickable_row}" (click)="view(data,'mobile')">
                <div class="flex table__content--mobile">
                    <div class="child"  *ngFor="let header of table_header | keyvalue:returnZero;let i = index; let end = last;" >
                       
                            <span *ngIf="header.key === 'status'" class="{{data[header.key] | lowercase}}  {{header.key}} text-center"> 
                                {{data[header.key]  | titlecase}}
                            </span>
                       
                        <div  *ngIf="i===0" >
                            {{data[header.key]}}
                        </div>
                        <div class="flex gap--8 mobile__width" *ngIf="header.value?.inner_key?.length">
                            <div *ngFor="let in_val of header.value?.inner_key; let j = index;">
                                <span class="info__bar--sm" [ngClass]="{'color--grey':j==1}">{{data[in_val]}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!last" class="border__bottom"></div>
        </div>
        <div class="data__not__available headling headline--lg" *ngIf="show_zero_stat">
            No results found.
        </div>
    </div>
    <ng-container *ngIf="table_data.length == 0">
        <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>
    </ng-container>
</div>
