import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-non-flip-chart',
  templateUrl: './non-flip-chart.component.html',
  styleUrls: ['./non-flip-chart.component.css'],
})
export class NonFlipChartComponent implements OnChanges {
  options: any;
  showCard: boolean = false;
  @Input() chartData: any;
  constructor() {}

  ngOnChanges() {
    this.showCard = this.chartData.value === 'loading' ? false : true;
    if (!this.chartData.id.includes('auto')) {
      if (this.chartData.d1 && !this.chartData.d1.length) {
        this.chartData.d1 = [
          ['1', 5],
          ['2', 5],
        ];
        this.chartData.f1 = 'rgb(255,255,255)';
        this.chartData.c1 = '#30c500';
      }
      if (this.chartData.percent >= 0) {
        this.chartData.c1 = '#30c500';
        this.chartData.f1 = 'rgba(48, 197, 0, 0.37)';
      } else {
        this.chartData.c1 = '#ff0000';
        this.chartData.f1 = 'rgba(255, 0, 0, 0.37)';
      }
      this.drawGradientChart(this.chartData.id);
    }
  }

  drawGradientChart(type) {
    this.options = {
      chart: {
        renderTo: type,
        // width: 100,
        height: 80,
      },
      xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      exporting: { enabled: false },
      yAxis: {
        visible: false,
      },
      series: [
        {
          marker: {
            enabled: false,
          },
          showInLegend: false,
          name: this.chartData.l,
          type: 'areaspline',
          color: this.chartData.c1,
          fillColor: this.chartData.f1,
          data: this.chartData.d1,
        },
      ],
    };
  }
}
