<div class="card-div-new bot" id="intelligenceBot" *ngIf="isOpen">
    <div class="bot-head">
        <div class="flex flex_attribute">
            <div class="card-heading">Insights</div>
            <img class="icon-cross" (click)="closeBot()" src="../../../assets/img/cross-icon.svg" />
        </div>
        <div class="card-sub-heading">{{ subHeading }}</div>
    </div>
    <div class="bot-content" #botContentBox>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let category of questionCategories" class="category-panel" (closed)="onCategorySelect()" (afterExpand)="onCategorySelect(category)">
                <mat-expansion-panel-header class="panel-height">
                    <mat-panel-title>
                        {{ category | titlecase }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <mat-accordion>
                        <mat-expansion-panel class="bot-question-panel" *ngFor="let question of questions[category]; let idx = index" [hideToggle]="true" (opened)="getAnswer(question)">
                            <mat-expansion-panel-header class="panel-size" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                                <mat-panel-title class="bot-question">
                                    {{ question?.question_text }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-skeleton-loader
                                [type]="'box'"
                                *ngIf="question.is_loading"
                                [details]="{
                                    styles: {
                                        height: '40px',
                                        margin: '0px 24px',
                                        width: 'calc(100% - 48px)'
                                    }
                                }"
                            ></app-skeleton-loader>
                            <div class="bot-answer" *ngIf="!question.is_loading" [ngClass]="question.response_type" [ngSwitch]="question.response_type">
                                <!-- numeric -->
                                <div *ngSwitchCase="'numeric'">
                                    <span class="rupee-symbol" *ngIf="question?.answer_titles[0] === 'rupees'">&#8377;</span>{{ question?.answer_values[0] | number : '1.0-2' }}
                                    <span class="unit" *ngIf="question?.answer_titles[0] !== 'rupees'">{{ question?.answer_titles[0] | titlecase }}</span>
                                </div>
                                <!-- text -->
                                <div *ngSwitchCase="'text'">
                                    <div class="text-option" *ngFor="let val of question?.answer_value">
                                        {{ val }}
                                    </div>
                                </div>
                                <!-- comparison -->
                                <div class="flex" *ngSwitchCase="'comparison'">
                                    <div>
                                        {{ question?.answer_values[0] }}
                                        <span class="unit" *ngIf="question?.answer_unit">{{ question?.answer_unit | titlecase }}</span>
                                        <div class="comp-param">({{ question?.answer_titles[0] | titlecase }})</div>
                                    </div>
                                    <div>
                                        {{ question?.answer_values[1] }}
                                        <span class="unit" *ngIf="question?.answer_unit">{{ question?.answer_unit | titlecase }}</span>
                                        <div class="comp-param">({{ question?.answer_titles[1] | titlecase }})</div>
                                    </div>
                                </div>
                                <!-- table -->
                                <div *ngSwitchCase="'table'">
                                    <app-generic-data-table
                                        [displayedColumns]="question?.answer_columns"
                                        [tableData]="question?.answer"
                                        [show_search]="false"
                                        [table_title]="question?.id"
                                        [hideTitle]="true"
                                        [cellProperties]="question?.cell_properties"
                                    ></app-generic-data-table>
                                </div>
                                <!-- line graph -->
                                <div *ngSwitchCase="'line-graph'">
                                    <app-generic-chart
                                        [chartData]="{
                                            title: '',
                                            titleXAxis: '',
                                            titleYAxis: '',
                                            chartType: 'spline',
                                            id: question?.answer_chart_id,
                                            chartSeriesData: question?.answer_chart_data,
                                            chartOptions: lineChartOptions
                                        }"
                                    ></app-generic-chart>
                                </div>
                                <!-- default -->
                                <div *ngSwitchDefault>No Data Present</div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
