<!-- <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
    <mat-icon>
      {{showIcon(i)}}
    </mat-icon>
  </button>
  <mat-error *ngIf="starCount == null || starCount == 0">
    Star count is <strong>required</strong> and cannot be zero
  </mat-error> -->
<!-- <button mat-icon-button  > -->
<!-- <mat-icon ngClass="{{showIcon(i)}}" class="star-icon" [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" >
        star
      </mat-icon> -->
<!-- </button> -->
<!-- <mat-error *ngIf="starCount == null || starCount == 0">
      Star count is <strong>required</strong> and cannot be zero
    </mat-error> -->
<img src="{{ icon }}" alt="rating icon" class="star-icon" *ngFor="let icon of ratingIcons" />
