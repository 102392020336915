import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../shared/loader/loader.service';

export interface ApiLoadingState {
    show:boolean
};

@Directive({
  selector: '[disableOnApi]',
  standalone: true
})
export class DisableOnApiDirective implements OnInit, OnDestroy {
  @Input() disableClass: string;
  private subscription: Subscription;

  constructor(private elementRef: ElementRef, 
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((loading_state:ApiLoadingState)=>{
        if(loading_state.show){
            this.elementRef.nativeElement.classList.add(this.disableClass);
        }else{
            this.elementRef.nativeElement.classList.remove(this.disableClass);
        }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}