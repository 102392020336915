<!-- <mat-card appearance="outlined" class="mat-elevation-z4" [ngClass]="chartData?.no_box ? 'noshadow' : 'box-shadow-card'" id="appGenericChart" *ngIf="chartData.chartSeriesData[0].d">
  <mat-card-header class="block_display">
    <mat-card-title class="overview_title text-left" *ngIf="chartData?.title">{{
      chartData?.title
      }}</mat-card-title>
    <mat-card-subtitle class="overview_sub_title chart_sub_title" *ngIf="chartData.value || chartData.value == 0">
      {{ chartData.value }}
      <span *ngIf="chartData.value && chartData.percent"> </span>
      <span class="negative_percent" *ngIf="chartData.percent && chartData.percent < 0">{{ chartData.percent }}%</span>
      <span class="positive_percent" *ngIf="chartData.percent && chartData.percent >= 0">+{{ chartData.percent }}%</span>
      <span *ngIf="chartData.value && chartData.percent"> </span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="chartData.value" id="{{ chartData.id }}" class="chart_margin">
    <charts [chartOptions]="chartData.chartOptions"></charts>
  </mat-card-content>
  <mat-card-content *ngIf="!chartData.value" id="{{ chartData.id }}">
    <charts [chartOptions]="chartData.chartOptions"></charts>
  </mat-card-content>
</mat-card> -->
<div class="thrive-card" id="appGenericChart" *ngIf="chartData.chartSeriesData[0].d">
  <h4 class="sub-heading mb-20" *ngIf="chartData?.title">{{chartData?.title}}</h4>
  <h6 class="overview_sub_title chart_sub_title" *ngIf="chartData.value || chartData.value == 0">
    {{ chartData.value }}
    <span *ngIf="chartData.value && chartData.percent"> </span>
    <span class="negative_percent" *ngIf="chartData.percent && chartData.percent < 0">{{ chartData.percent }}%</span>
    <span class="positive_percent" *ngIf="chartData.percent && chartData.percent >= 0">+{{ chartData.percent }}%</span>
    <span *ngIf="chartData.value && chartData.percent"> </span>
  </h6>
  <div *ngIf="chartData.value" id="{{ chartData.id }}" class="chart_margin">
    <charts [chartOptions]="chartData.chartOptions"></charts>
  </div>
  <div *ngIf="!chartData.value" id="{{ chartData.id }}">
    <charts [chartOptions]="chartData.chartOptions"></charts>
  </div>
</div>
<app-skeleton-loader [type]="'box'" *ngIf="!chartData.chartSeriesData[0].d" [details]="{ styles: { height: '280px' } }"></app-skeleton-loader>