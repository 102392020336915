<div class="text-right" id="congrats">
    <img (click)="confirm()" src="../../../assets/img/cross-icon.svg" />
</div>
<div class="text-center congrats__text">
    <app-icon
              [iconName]="'congrats-icon'"
              [iconStyles]="{ width: '71px', height: '70px' }"></app-icon>
    <div class="contrats__title">
        {{ data.title }}
    </div>
    <div class="congrats__content">
        {{ data.content }}
    </div>
    <div class="congrats__link">
        <a [href]="data.link" target="_blank">{{ data.link }}</a>
    </div>
    <div class="congrats__btn">
        <button class="btn-thrive_primary" (click)="confirm()">
            {{ data.button_text }}
        </button>
    </div>
</div>
