import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.css'],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() type: string;
  @Input() details: any;
  tableData: any = [];
  showSearch: boolean = false;
  constructor() {}

  ngOnInit() {
    switch (this.type) {
      case 'table':
        let obj = {};
        this.details.columns.forEach((col) => {
          obj[col] = '';
        });
        for (let i = 0; i < this.details.rows; i++) {
          this.tableData.push(obj);
        }
        if (this.details.showSearch !== undefined) {
          this.showSearch = this.details.showSearch;
        }
      case 'box':
        if (!this.details) {
          this.details.styles = {};
        }
        break;
    }
  }
}
