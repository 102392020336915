<div class="text-center" *ngIf="data.type === 'confirm'">
  <div class="text-center">
    <i
      *ngIf="data.icon !== 'bell'"
      class="material-icons"
      style="color: #fcb827; font-size: 85px"
      >error_outline</i
    >
    <img
      *ngIf="data.icon === 'bell'"
      class="noti__icon"
      src="../../../assets/img/notification.svg"
    />
  </div>
  <h2 class="title">{{ data.message }}</h2>
  <div *ngIf="data.content" class="content" [innerHTML]="data.content"></div>
  <div *ngIf="data.showButtons">
    <button (click)="onCancel()" class="btn white--btn">
      {{ data.noButtonText }}
    </button>
    <button (click)="onSuccess()" class="btn purple--btn">
      {{ data.yesButtonText }}
    </button>
  </div>
</div>

<div class="mx-10 my-15 text-center" *ngIf="data.type === 'success'">
  <div class="text-center">
    <i class="material-icons success">check_circle_outline</i>
  </div>
  <h2 class="title" [innerHTML]="data.message"></h2>
  <div *ngIf="data.content" class="content">{{ data.content }}</div>
  <div *ngIf="data.showButtons">
    <button (click)="onSuccess()" class="btn purple--btn">
      {{ data.yesButtonText }}
    </button>
    <button (click)="onCancel()" class="btn white--btn">
      {{ data.noButtonText }}
    </button>
  </div>
</div>

<div class="mx-10 my-15 text-center" *ngIf="data.type === 'failure'">
  <div class="text-center">
    <i class="material-icons failure">highlight_off</i>
  </div>
  <h2 class="title">{{ data.message }}</h2>
  <div *ngIf="data.content" class="content" [innerHTML]="data.content"></div>
</div>
<div class="mx-10 my-15 text-center" *ngIf="data.type === 'processing'">
  <div class="text-center">
    <mat-spinner [diameter]="60" [color]="accent"></mat-spinner>
  </div>
  <h2 class="title">{{ data.message }}</h2>
  <div *ngIf="data.content" class="content" [innerHTML]="data.content"></div>
</div>

<div class="mx-10 my-15 text-center" *ngIf="data.type === 'warning'">
  <div class="text-center">
    <i class="material-icons warning">error_outline</i>
  </div>
  <h2 class="title">{{ data.message }}</h2>
  <div *ngIf="data.content" class="content" [innerHTML]="data.content"></div>
</div>

<div class="mx-10 my-15 text-center" *ngIf="data.type === 'failure-confirm'">
  <div class="text-center">
    <i class="material-icons failure">highlight_off</i>
  </div>
  <h2 class="title">{{ data.message }}</h2>
  <div *ngIf="data.content" class="content">{{ data.content }}</div>
  <button (click)="onSuccess()" class="btn purple--btn">Okay</button>
</div>
