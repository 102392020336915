<div id="subHeaderContainer">
  <div id="subHeader">
    <div *ngIf="searchFilter && searchFilter?.show">
      <div class="common-search-box flex">
        <input
               (input)="onChange($event, 'search')"
               [placeholder]="searchFilter?.placeholder" />
        <mat-icon matSuffix>search</mat-icon>
      </div>
    </div>
    <div>
      <div
           class="feature_dropdown"
           *ngIf="
          featureFilterDetails?.show && featureFilterDetails?.list?.length > 0
        ">
        <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="outline"
                        floatLabel="never"
                        class="mx-10">
          <mat-select
                      [(value)]="featureFilterDetails.selected"
                      (selectionChange)="onChange($event, 'feature')">
            <mat-option
                        *ngFor="let f of featureFilterDetails?.list"
                        [value]="f">{{ f.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="date-dropdown" *ngIf="isTimeFilter">
        <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="outline"
                        floatLabel="never"
                        class="mx-10"
                        [ngStyle]="{ width: dateSelected === 'custom' ? '175px' : '150px' }">
          <mat-select
                      #dateFilterSelect
                      [(value)]="dateSelected"
                      (selectionChange)="onChange($event, 'time')">
            <mat-option *ngFor="let date of dateRange" [value]="date.value">
              <span *ngIf="date.value !== 'custom'">{{ date.name }}</span>
              <span *ngIf="date.value === 'custom'">
                <span
                      class="date-to-display"
                      *ngIf="dateSelected === 'custom'">{{ date_to_display }}</span>
                <input
                       readonly
                       class="custom-datepicker"
                       [owlDateTimeTrigger]="custom_datepicker"
                       [owlDateTime]="custom_datepicker"
                       [selectMode]="'range'"
                       [formControl]="selected_date"
                       (dateTimeChange)="onCustomDateChange($event)"
                       [min]="min_custom_date"
                       [max]="max_custom_date"
                       placeholder="Custom" />
                <owl-date-time
                               [pickerType]="'calendar'"
                               [pickerMode]="'dialog'"
                               #custom_datepicker></owl-date-time>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="buttonFilterDetails?.show">
        <button
                [class]="buttonFilterDetails?.class"
                [ngClass]="{ 'disabled-button': buttonFilterDetails?.disabled }"
                (click)="onClick()"
                [disabled]="buttonFilterDetails.disabled"
                [ngClass]="{ 'disabled-referral': buttonFilterDetails.disabled }"
                class="btn-thrive_primary">
          {{ buttonFilterDetails.text }}
        </button>
      </div>
      <div *ngIf="newButton?.show">
        <button
                [id]="newButton?.id"
                class="action-button customer-list-btn btn-thrive_primary"
                (click)="newButtonClickFun()">
          <img src="../../../assets/img/{{ newButton?.iconname }}" />{{
          newButton?.text
          }}
        </button>
      </div>
      <div *ngIf="buttonDisable?.show">
        <button
                [class]="buttonDisable?.class"
                (click)="onClickDisable()"
                class="btn-thrive_primary--light">
          {{ buttonDisable.text }}
        </button>
      </div>
    </div>
  </div>
</div>
