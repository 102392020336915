import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Toast,ToasterService } from '../toaster.service';
import { ToasterComponent } from '../toaster.component';

@Component({
    selector: 'toaster-container',
    standalone: true,
    imports: [CommonModule,ToasterComponent],
    templateUrl: './toaster-container.component.html',
})

export class ToasterContainerComponent implements OnInit{
    toasts: Toast[] = [];

    constructor(public toaster: ToasterService) {}
  
    ngOnInit() {
      this.toaster.toast$
        .subscribe(toast => {
          this.toasts = [toast, ...this.toasts];
        });
    }
    // Remove notification form toasts
    remove(index: number) {
      this.toasts = this.toasts.filter((v, i) => i !== index);
    }
}
