import { Component, OnChanges, Input } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-generic-gradient-chart',
  templateUrl: './generic-gradient-chart.component.html',
  styleUrls: ['./generic-gradient-chart.component.css'],
})
export class GenericGradientChartComponent implements OnChanges {
  @Input() chartData: any;
  options: any;
  lineColor: any;
  g1: any;
  g2: any;
  constructor() {}

  ngOnChanges() {
    if (this.chartData && this.chartData.d1 && !this.chartData.d1.length) {
      this.chartData.d1 = [
        ['1', 5],
        ['2', 5],
      ];
      this.chartData.g1 = 'rgb(255,255,255)';
    }
    if (this.chartData.percent >= 0) {
      this.lineColor = '#30c500';
      this.g1 = 'rgb(48,197,0)';
      this.g2 = 'rgb(238,255,232)';
    } else {
      this.lineColor = '#ff0000';
      this.g1 = 'rgb(255,44,0)';
      this.g2 = '#FFE1E1';
    }
    this.drawGradientChart(this.chartData.id);
  }
  drawGradientChart(type) {
    this.options = {
      chart: {
        renderTo: type,
        // type : 'spline',
        // width: 100,
        height: 140,
        spacingBottom: 0,
        // spacingTop: -30,
        spacingLeft: 0,
        spacingRight: 0,
      },
      exporting: { enabled: false },
      xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          marker: {
            enabled: false,
          },
          showInLegend: false,
          name: this.chartData.l,
          type: 'areaspline',
          color: this.lineColor,
          fillColor: {
            linearGradient: [0, 0, 0, 150],
            stops: [
              [0, this.g1],
              [1, this.g2],
            ],
          },
          data: this.chartData.d1,
        },
      ],
    };
  }

  flip(id) {
    $('#flip-card-' + id).toggleClass('flipped');
  }
}
