import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule, NGX_ECHARTS_CONFIG } from 'ngx-echarts';

@Component({
    selector: 'charts',
    standalone: true,
    imports: [CommonModule, NgxEchartsModule],
    template: `
        <div echarts *ngIf="chartType === 'line'" [options]="lineChartOptions" [merge]="chartOptions" class="demo-chart"></div>
        <div echarts *ngIf="chartType === 'pie'" [activeEntries]="activeEntries" (chartClick)="onChartEvent($event)" [options]="pieChartOptions" [merge]="chartOptions" class="demo-chart"></div>
        <div echarts *ngIf="chartType === 'bar'" [options]="barChartOptions" [merge]="chartOptions" class="demo-chart"></div>
        <div echarts *ngIf="!chartType" [options]="chartOptions" class="demo-chart"></div>
    `,
    providers: [
        {
            provide: NGX_ECHARTS_CONFIG,
            useFactory: () => ({ echarts: () => import('echarts') })
        },
    ]
})
export class ChartsComponent {
    activeEntries: any;
    @Input() chartOptions: any;
    @Input() chartType: any;
    @Output() chartClicked = new EventEmitter<string>();

    lineChartOptions = {
        xAxis: {
            type: 'category',
            nameLocation: 'middle',
            nameGap: 50,
            nameTextStyle: {
                fontWeight: 'bold'
            }
        },
        yAxis: {
            type: 'value',
            nameLocation: 'middle',
            nameGap: 40,
            axisPointer: { show: false },
            nameTextStyle: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis',
            show: true,
            axisPointer: { type: 'cross' }
        },
        lineStyle: {
            color: "#2C5E95",
            width: "2px"
        },
        series: [
            {
                type: 'line',
                smooth: true,
                areaStyle: {
                    color: "#2C5E9512"
                },
                showSymbol: false
            }
        ]
    }

    colors = ['#2C5E95', '#67B3C2', '#81B98C', '#BED443', '#F5B612'];
    pieChartOptions = {
        tooltip: {
            trigger: 'item'
        },
        grid: {
            height: '150px'
        },
        series: [
            {
                type: 'pie',
                radius: ['30%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                color: this.colors
            }
        ]
    }

    barChartOptions = {
        xAxis: {
            type: 'category',
            axisLabel: {
                rotate: 45
            },
            nameLocation: 'middle',
            nameGap: 50,
            nameTextStyle: {
                fontWeight: 'bold'
            }
        },
        yAxis: {
            type: 'value',
            nameLocation: 'middle',
            nameGap: 40,
            axisPointer: { show: false },
            nameTextStyle: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis',
            show: true,
            axisPointer: { type: 'cross' }
        }
    };

    onChartEvent(event: any) {
        this.activeEntries = [{name: event.data.name}];
        this.chartClicked.emit(event);
    }
}
