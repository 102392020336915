import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule,MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {ApiService} from '../../../utility/services/api.service';
import {DataService} from '../../../utility/services/data.service';
import {Observable, map, startWith} from 'rxjs';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MixpanelEvents, MixpanelService} from '../services/mixpanel.service';
import { filter, switchMap,debounceTime } from 'rxjs/operators';

@Component({
    selector: 'outlet-selector',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatTooltipModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule],
    templateUrl: './outlet-selector.component.html',
    styleUrls: ['./outlet-selector.component.scss']
})
export class OutletSelectorComponent implements OnInit {
    @ViewChild('outletAutoComplete') outletAutoComplete: ElementRef;
    merchant: any;
    userRole: string;
    isOutletSelectionVisible: boolean = false;
    selectedOutlet = new FormControl<string | any>('');
    filteredOptions: Observable<any>;
    constructor(private apiService: ApiService, public dataService: DataService, private _eref: ElementRef, private trackingService: MixpanelService) {}
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    @HostListener('document:click', ['$event.target']) onDocumentClick(event) {
        if (!this._eref.nativeElement.contains(event) && !event.classList.contains('mat-mdc-autocomplete-trigger')) {
            this.isOutletSelectionVisible = false;
            if(this.autocompleteTrigger)
            this.autocompleteTrigger.closePanel();
        } else {
            this.isOutletSelectionVisible = true;
        }
    }

    ngOnInit(): void {
        this.merchant = this.dataService.getDataFromStore('merchantInfo');
        this.userRole = this.dataService.getDataFromStore('merchant_user').role;
        // when user enter 2 or more charcater then calling get outlets api 
        this.filteredOptions = this.selectedOutlet.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            switchMap((value) => {
                if(value.length >= 2){
                    return  this.getOutlets(value);
                } else if(value.length < 2 && value.length >= 1){
                   return [];
                } else {
                    return this.getOutlets(value);
                }
            }), 
        );
    }
    /**
     * this function is used to show selected merchant name
     * @param user user object which contain name id etc..
     * @returns returen user name 
     */
    displayFn(user: any): string {
        return user && user.name ? user.name : '';
    }
    /**
     * When user search from search input then get outlets api called
     * @param searchText user enter string 
     * @returns return search object 
     */
    getOutlets(searchText?:string) {
        return  this.apiService.getData('/v3/merchant_users/search',{search_query:searchText}).pipe(
        filter(data => !!data),
        map((data:any) => {
            if(!data.length){
                data = [{id:0,name:'No result found!',location:''}]
            }
            return data
        })
        )
    }
    /**
     * when user click on drop down merchant search appear in frontend 
     */
    showOutletSelection() {
        this.isOutletSelectionVisible = !this.isOutletSelectionVisible;
        setTimeout(() => {
            this.outletAutoComplete?.nativeElement.focus();
        }, 0);
    }
    /**
     * This function is trigger when user select outlet from list
     * @param outlet Selected outlet object 
     */
    outletSelected(outlet:any) {
        this.trackingService.trackEvents(MixpanelEvents.outletSwitch, {switch: JSON.stringify(outlet)});
        this.changeMerchant(outlet.authentication_token);
        this.isOutletSelectionVisible = false;
    }
    /**
     * This function is used for switching between merchant
     * @param token Merchant token 
     */
    changeMerchant(token: string) {
        this.apiService.postData('/v3/merchant_users/assign_merchants_to_user', {merchant_token: token}).subscribe((data: any) => {
            this.dataService.login(data, 'change_merchant');
            setTimeout(() => {
                window.location.reload();
            }, 0);
        });
    }
}
