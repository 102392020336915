import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { dateRange } from '../../../global';
import { Router } from '@angular/router';
let today = new Date(new Date().setHours(0, 0, 0, 0));
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css'],
})
export class SubHeaderComponent implements OnInit {
  merchantName: string;
  merchantLocation: string = '';
  dateRange: any;
  dateSelected: string;
  min_custom_date: any = '';
  max_custom_date: any = today;
  date_to_display = '';
  fList: {}[] = [];
  fSelected: any;
  fStatus: boolean;
  @Input() dateRangeDetails: any;
  @Input() isLocationFilter: boolean;
  @Input() isTimeFilter: boolean;
  @Input() featureFilterDetails: any;
  @Input() searchFilter: any;
  @Input() buttonFilterDetails: any;
  @Input() buttonDisable: any;
  @Input() featureFilterEvent: any;
  @Output() onDateChange = new EventEmitter<any>();
  @Output() onfeatureFilterChange = new EventEmitter<any>();
  @Output() onButtonFilterClick = new EventEmitter<string>();
  @Output() onSearch = new EventEmitter<string>();
  @Input() newButton: any;
  @Output() onButtonClick = new EventEmitter<any>();
  selected_date = new UntypedFormControl('');
  @ViewChild('dateFilterSelect', { static: false }) dateFilterSelect;
  constructor(private router: Router) {}

  ngOnInit() {
    this.dateRange = this.dateRangeDetails ? this.dateRangeDetails : dateRange;
    this.dateSelected = this.dateRange[0].value;
    if (this.router.url === '/feedback' && this.featureFilterDetails.show) {
      this.fSelected = this.fList[0];
    } else if (this.isTimeFilter) {
      this.onChange(this.dateSelected, 'time');
    }
    if (!this.searchFilter || Object.keys(this.searchFilter).length === 0) {
      this.searchFilter = {
        placeholder: '',
        show: false,
      };
    }
  }

  onChange(event, type) {
    let value = '';
    switch (type) {
      case 'time':
        value = event.value ? event.value : event;
        if (value !== 'custom') {
          this.onDateChange.next(value);
          this.selected_date.setValue('');
        }
        break;
      case 'feature':
        value = event.value ? event.value : event;
        let obj = {
          selectedOption: value,
          status: this.fStatus,
        };
        this.onfeatureFilterChange.next(obj);
        break;
      case 'search':
        this.onSearch.next(event.target.value);
        break;
    }
  }

  onCustomDateChange(event: any) {
    this.dateSelected = 'custom';
    let selected_value = {
      type: 'custom',
      start_date: moment(event.value[0]).format('DD/MM/YYYY'),
      end_date: moment(event.value[1]).format('DD/MM/YYYY'),
    };
    this.selected_date.setValue(event.value);
    this.onDateChange.next(selected_value);
    this.dateFilterSelect.close();
    this.date_to_display =
      selected_value.start_date + ' ~ ' + selected_value.end_date;
  }

  onClick() {
    if (this.buttonFilterDetails && this.buttonFilterDetails.routerLink) {
      this.router.navigateByUrl(this.buttonFilterDetails.routerLink);
    } else {
      this.onButtonFilterClick.emit(this.buttonFilterDetails.clickMessage);
    }
  }
  newButtonClickFun() {
    this.onButtonClick.emit();
  }
  onClickDisable() {
    this.onButtonClick.emit();
  }
}
