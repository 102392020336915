import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
@Component({
    selector: 'app-mat-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class StarRatingComponent implements OnInit {
    @Input('rating') rating: number;
    @Input() selected: string;
    @Input() default: string;
    @Input('starCount') starCount: number;

    ratingIcons: any = [];

    ngOnInit() {
        for (let i = 0; i < this.starCount; i++) {
            let icon = i < this.rating ? this.selected : this.default;
            this.ratingIcons.push(icon);
        }
    }
}
