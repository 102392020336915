<div class="modal-container">
    <div class="left-content">
        <p>Activate <span>PRO plan FREE for 30 days.</span> Enjoy all the PRO plan features now!</p>
        <img src="assets/img/free-trial.png" alt="Free Trial">
    </div>
    <div class="right-content">
        <span>What you’ll get</span>

        <p>Custom WhatsApp, SMS and Email Templates.</p>
        <p>Manual marketing campaigns.</p>
        <p>Automated marketing communication.</p>
        <p>Loyalty program & advanced loyalty settings.</p>
        <p>Completely customisable feedback management system.</p>
        <p>Analytics and reporting.</p>
        <p>Sales Intelligence.</p>

        <button class="btn-thrive_primary" mat-button (click)="enableTrial()">Enable Free Trial</button>
    </div>
</div>