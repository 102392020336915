<ng-template [ngIf]="userRole !== 'Merchant'" [ngIfElse]="showNameLocation">
  <div class="outlet-selector" (click)="showOutletSelection()">
    <div class="outlet-details">
      <span>{{ merchant?.name }}</span>
      <span *ngIf="merchant?.location && merchant?.location?.length">
        <mat-icon>location_on</mat-icon>
        <span>{{ merchant?.location }}</span>
      </span>
    </div>
    <div class="outlet-dropdown">
      <p class="outlet-id" matTooltip="MID is the Merchant Identification Number" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
        MID: {{ merchant.id }}
      </p>
      <button class="btn-thrive_icon">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <div class="outlet-dropdown-panel">
      <form class="example-form">
        <mat-form-field [ngClass]="{'hide-autocomplete':!isOutletSelectionVisible}" class="example-full-width w-100" appearance="outline" subscriptSizing="dynamic">
          <input type="text" #trigger="matAutocompleteTrigger" matInput placeholder="Start typing to search your Outlet..." [formControl]="selectedOutlet" [matAutocomplete]="auto" #outletAutoComplete />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="outletSelected($event.option.value)">
            <mat-option [disabled]="option.id === 0" *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option?.name }} <span *ngIf="option.id !== 0">-</span> {{ option.location }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #showNameLocation>
  <div class="outlet-selector sigle-merchant">
    <div class="outlet-details cursor-auto">
      <span>{{ merchant?.name }}</span>
      <span *ngIf="merchant?.location && merchant?.location?.length">
        <mat-icon>location_on</mat-icon>
        <span>{{ merchant?.location }}</span>
      </span>
    </div>
    <div class="outlet-dropdown">
      <p class="outlet-id" matTooltip="MID is the Merchant Identification Number" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
        MID: {{ merchant.id }}
      </p>
    </div>
  </div>
</ng-template>