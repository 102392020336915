<div class="dialog__status text-center">
  <mat-icon class="close" (click)="onClick('close')">close</mat-icon>
  <div class="text-center" *ngIf="data.icon">
    <i class="material-icons" [ngClass]="data.type">{{ data.icon }}</i>
  </div>
  <div class="text-center" *ngIf="data.app_icon">
    <app-icon
      [iconName]="data.app_icon"
      [iconStyles]="{ width: '85px', height: '85px' }"
    ></app-icon>
  </div>
  <h2 class="title">{{ data?.title }}</h2>
  <div class="content" [innerHtml]="data?.content"></div>
  <div *ngIf="data.show_btn" class="flex">
    <button
      (click)="onClick('yes')"
      class="btn__confirm btn__confirm--yes btn__confirm--multiple"
    >
      Yes
    </button>
    <button
      (click)="onClick('no')"
      class="btn__confirm btn__confirm--no btn__confirm--multiple"
    >
      No
    </button>
  </div>
  <button
    *ngIf="data.show_single_btn"
    (click)="onClick(data.scroll ? 'scroll' : 'close')"
    class="btn__confirm btn__confirm--yes btn__confirm--single"
  >
    {{ data.show_single_btn }}
  </button>
  <div *ngIf="data.show_btn_notify" class="flex">
    <button
      (click)="onClick('no')"
      class="btn__confirm btn__confirm--no btn__confirm--multiple"
    >
      Cancel
    </button>
    <button
      (click)="onClick('yes')"
      class="btn__confirm btn__confirm--yes btn__confirm--multiple"
    >
      Proceed
    </button>
  </div>
  <div class="flex notify__radio" *ngIf="data.show_radio">
    <label class="checkbox__custom">
      <input (change)="toggleCheckbox($event)" type="checkbox" />
      <span class="checkbox__custom__content">Don't show this again</span>
      <span
        class="checkbox__custom__checkmark checkbox__custom__checkmark--sm"
      ></span>
    </label>
  </div>
</div>
