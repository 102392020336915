import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../../shared/loader/loader.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataService } from '../services/data.service';
@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.showLoader();
        let auth_token: any = this.dataService.getDataFromStore('merchant_user_auth_token');
        //check to auto logout users having old auth token stored in their browser storage
        if(auth_token?.authentication_token){
          this.onEnd();
          this.dataService.clearLocalstorage();
          return EMPTY;  
        }
        //for external urls don't include auth token
        if (!(req.url.includes('https://graph.facebook.com')|| req.url.includes('https://cdn.growthbook.io/'))) {
          if (auth_token) {
            req = req.clone({ headers: req.headers.set('UserAuthorization', auth_token) });
          }
        }
      
        //redirect user to login page if user authorization token are not present
        if (!(req.url.includes('/v3/merchant_users/generate_otp') 
          || req.url.includes('merchant_users/verify_otp') 
          || req.url.includes('/sign_up') 
          || req.url.includes('/auth_code_login')
          || req.url.includes('https://cdn.growthbook.io/')) && !req.headers.has('UserAuthorization')) {
            this.onEnd();
            this.dataService.clearLocalstorage();
            return EMPTY;
        }
        
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
        }, (err: any) => {
            const config = new MatSnackBarConfig();
            config.verticalPosition = 'top';
            config.duration = 2000;
            config.panelClass = ['error-snackbar'];
            switch (err.status) {
                case 401:
                    let msg = err.error && err.error.errors ? err.error.errors : err.error.error;
                    this.snackBar.open(msg, '', config);
                    this.dataService.clearLocalstorage();
                    break;
            }
            this.onEnd();
        }));
    }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
