<div id="subscriptionPlan">
    <div class="title-row">
        <h3 *ngIf="!hideFreePlan">{{title}}</h3>
        <div class="group-button">
            <div>
                <mat-button-toggle-group class="pretitle" [(ngModel)]="duration" (change)="changeDuration($event)">
                    <mat-button-toggle [value]="false">Quarterly</mat-button-toggle>
                    <mat-button-toggle [value]="true">Yearly</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="arrow" *ngIf="subscription?.max_discount > 0">
                <img src="../../../../assets/img/TMS-pricing/offfer-arrow.png" />
            </div>
            <div *ngIf="subscription?.max_discount > 0" class="offer">{{ subscription?.max_discount }} % OFF</div>
        </div>
    </div>
    <thrive-loader *ngIf="!plans[duration ? 'years' : 'months'].length || addonClicked" [loaderHeight]="'578px'"></thrive-loader>
    <div class="plan-list" *ngIf="plans[duration ? 'years' : 'months'].length && !addonClicked">
        <ng-container *ngFor="let plan of plans[duration ? 'years' : 'months']">
            <div class="plan-card" [ngClass]="{'active-card': subscription.currently_active_plan === plan.code }">
                <div *ngIf="plan.plan_name === 'Pro'" class="pretitle recommend">
                    <mat-icon class="thumb">thumb_up</mat-icon>
                    Thrive recommends
                </div>
                <h3>{{ plan.plan_name }}</h3>
                <div class="active-mark" *ngIf="subscription.currently_active_plan === plan.code">
                    <mat-icon>check_circle</mat-icon>
                    <span>Currently active</span>
                </div>
                <div *ngIf="plan?.code!=='custom_plan'">
                    <div class="plan-duration" *ngIf="plan.plan_name === 'Pro'">
                        <span>&#8377;</span> {{plan.price_per_month }} /month
                    </div>
                    <div class="plan-duration" *ngIf="plan.plan_name === 'Premium'">
                        Pro: <span>&#8377;</span> {{plan.price_per_month }} /month +
                        <br />
                        Offline CRM: <span>&#8377;</span> {{plan.offline_plan_per_store_price }} per store/month
                    </div>
                </div>
                <div class="plan-duration" *ngIf="plan?.code==='custom_plan'">
                    {{plan.plan_price}}
                </div>
                <div class="section">
                    <div class="gray-text">What you’ll get</div>
                    <div class="features" *ngFor="let features of plan.store_markup_description">
                        <mat-icon class="icon check-icon" matListItemIcon>check_circle</mat-icon>
                        {{ features }}
                    </div>
                </div>
                <div class="section" *ngIf="plan?.addons?.length">
                    <div class="gray-text">Add ons</div>
                    <div *ngFor="let addon of plan.addons;" class="features">
                        {{addon.store_markup_description}}
                    </div>
                </div>
                <!-- Outlet selection start -->
                <div *ngIf="plan.plan_name === 'Premium' && this.merchantInfo.role === 'ChainOwner'">
                    <div class="gray-text">Stores selected</div>
                    <mat-form-field class="select-outlet" appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Choose store</mat-label>
                        <mat-select name="selectStore" [formControl]="plan.selected_merchant" multiple>
                            <mat-option>
                                <ngx-mat-select-search
                                                       placeholderLabel="Search"
                                                       noEntriesFoundLabel="Store not found"
                                                       ngDefaultControl
                                                       [formControl]="search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option [disabled]="plan.plan_name === merchantInfo?.subscription_details?.plan_name" #allSelected (click)="plan.code !== subscription.currently_active_plan ? toggleSelectAll(plan):''" [value]="0">All Stores</mat-option>
                            <mat-option [disabled]="plan.plan_name === merchantInfo?.subscription_details?.plan_name" *ngFor="let merchant of plan.outlet_list | filter : search.value : ['location'];" (click)="toggleSingleOutlet(allSelected.viewValue,plan)" [value]="merchant.id">{{ merchant.location }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="plan.selected_merchant.errors">Required</mat-error>
                    </mat-form-field>
                </div>
                <!-- Outlet let selection end -->
                <button mat-button class="btn-thrive_secondary btn-bottom" [ngClass]="{'disable-elem':plan?.button_text === 'Use All Outlets Dashboard'}" disableOnApi [disableClass]="'saving'" *ngIf="plan?.button_text" (click)="subscribePlan(plan)">
                    {{ plan?.button_text}}
                </button>
                <div class="thrive-toast_warn embedded">
                    After completing the payment, kindly reload the dashboard. It takes upto 5 minutes for the new plan to reflect.
                </div>
            </div>
        </ng-container>
    </div>
    <p class="content">
        <span>Want to change the plan, add/remove add-ons, or need any other assistance </span>
        <a *ngIf="innerWidth > 768" class="whatsapp-link" target="_blank" href="https://web.whatsapp.com/send?phone=919833485073">
            Chat with us on WhatsApp
            <img src="../../../../assets/img/TMS-pricing/whatsapp-white.png" />
        </a>
        <span *ngIf="innerWidth < 768">  Chat with us on WhatsApp 9833485073</span>
    </p>
</div>