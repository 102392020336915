import { Component, OnChanges, Input } from '@angular/core';
import { NumberFormatter } from '../../../../global';

@Component({
  selector: 'app-generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.css'],
})
export class GenericChartComponent implements OnChanges {
  @Input() chartData: any;
  options: any;
  constructor(public numberFormatter: NumberFormatter) {}

  ngOnChanges() {
    let labels = [];
    if (
      this.chartData.chartSeriesData[0].d !== null &&
      this.chartData.chartSeriesData[0].d !== undefined
    ) {
      labels = this.chartData.chartSeriesData[0].d.map((arr) => {
        return arr[0];
      });
    }
    this.drawChart(this.chartData, labels);
  }

  hexToRgb(hex, opacity: number) {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    let result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    return result;
  }

  drawChart(chartData: any, labels: any) {
    let self = this;
    let seriesData = [];
    let chartType = chartData.chartType ? chartData.chartType : 'areaspline';
    chartData.chartSeriesData.forEach((data) => {
      const obj = {
        marker: {
          enabled: false,
        },
        showInLegend: true,
        name: data.l,
        type: chartType,
        color: data.c,
        zIndex: data.zIndex ? data.zIndex : undefined,
        dashStyle: data.dashStyle ? data.dashStyle : 'solid',
        data: data.d,
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      };
      if (data.removeFill) {
        obj['fillColor'] = false;
      } else {
        obj['fillColor'] =
          data.c && data.c.linearGradient ? data.c : this.hexToRgb(data.c, 0.8);
      }
      seriesData.push(obj);
    });
    this.options = {
      chart: {
        renderTo: chartData.id,
        height: 300,
      },
      exporting: { enabled: false },
      title: {
        text: null,
      },
      xAxis: {
        tickInterval: 1,
        categories: labels,
        title: {
          text: chartData.titleXAxis,
        },
      },
      yAxis: {
        title: {
          text: chartData.titleYAxis,
          style: chartData.titleYAxisStyles
            ? chartData.titleYAxisStyles
            : { color: '#666666' },
        },
      },
      legend: {
        enabled: chartData.removeLegend ? false : true,
        // layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
      },
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: '#000000',
        borderColor: '#000000',
        borderRadius: 8,
        padding: 0,
        shadow: false,
        formatter: function () {
          let tooltip = `<div style="padding:10px 20px">
          <div style="margin-bottom:10px;font-weight: bold;color:#fff;text-decoration:underline;display:${
            self.chartData.tooltipHeadline ? 'block' : 'none'
          }">${self.chartData.tooltipHeadline}</div>
          ${this.points
            .map((point) => {
              return `<div style="color: #ababab;font-size:12px;">${
                point.series.userOptions.name
              }</div>
            <div style="margin-bottom:15px;font-size: 16px;color: #fff;">${
              self.chartData.showCurrency
                ? '₹' + self.numberFormatter.nFormatter(point.y)
                : self.numberFormatter.nFormatter(point.y)
            }</div>`;
            })
            .join('')}
          </div>`;
          return tooltip;
        },
      },
      credits: {
        enabled: false,
      },
      series: seriesData,
    };
  }
}
