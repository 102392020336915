import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class IconComponent implements OnInit {
  iconUrl: string = '../assets/img/icon.sprites.svg';
  stylesProp = ['fill', 'height', 'width', 'cursor'];
  style: any = {
    fill: '#545454',
    height: '20px',
    width: '20px',
    cursor: 'default',
  };
  @Input() iconName: string;
  @Input() iconStyles: any;

  ngOnInit() {
    this.stylesProp.forEach((prop) => {
      if (this.iconStyles[prop]) {
        this.style[prop] = this.iconStyles[prop];
      }
    });
  }
}
