<div class="card-div container" id="genericDataTable">
    <app-skeleton-loader
        [type]="'table'"
        *ngIf="tableData && tableData[0] === 'loading'"
        [details]="{
            columns: displayedColumns,
            rows: 10,
            hideTitle: hideTitle,
            tableTitle: table_title,
            showSearch: show_search
        }"
    ></app-skeleton-loader>
    <div appearance="outlined" class="thrive-card no-hover" *ngIf="tableData && tableData[0] !== 'loading'">
        <div *ngIf="!hideTitle" style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px">
            <p class="basetitle normal">{{ table_title }}</p>
            <div style="display: flex; align-items: center; gap: 20px">
                <ng-container *ngIf="featureFilterDetails?.show && featureFilterDetails?.list?.length > 0">
                    <ng-container *ngIf="showFeatureFilter">
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never">
                            <mat-label>Filter</mat-label>
                            <mat-select [(value)]="featureFilterDetails.selected" (selectionChange)="onChange($event, 'feature')">
                                <mat-option *ngFor="let f of featureFilterDetails?.list" [value]="f">{{ f.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" *ngIf="show_search">
                    <mat-label>Search</mat-label>
                    <input [placeholder]="search_placeholder" matInput [(ngModel)]="searchValue" />
                </mat-form-field>
            </div>
        </div>
        <div class="thrive-table">
            <mat-table #table matSort [dataSource]="(show_search ? (tableData | userFilter : searchValue) : tableData) | paginate : config" (matSortChange)="sortData($event)" class="table-container">
                <ng-container *ngFor="let col of displayedColumns; let i = index" matColumnDef="{{ col }}">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [disabled]="isSortingDisabled(col)"
                        [ngClass]="{
                            text_right: displayedColumns?.length === 2 && i === 1
                        }"
                        [ngStyle]="cellProperties[i]"
                    >
                        {{ tableHeadings[col] }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [ngClass]="{
                            text_right: displayedColumns?.length === 2 && i === 1
                        }"
                        [ngStyle]="cellProperties[i]"
                    >
                        <div [ngClass]="{fsi: col === 'name'}">
                            {{ col === 'revenue' || col === 'spent' || col === 'sales' || col === 'gross_revenue' ? '₹ ' + numberFormatter.nFormatter(element[col]) : element[col] }}
                        </div>
                        <div class="mobile-text" *ngIf="col === 'name' && !displayedColumns.includes('mobile')">
                            {{ element['mobile'] }}
                        </div>
                        <div class="mobile-text" *ngIf="col === 'name' && element['category']">
                            {{ element['category'] }}
                        </div>
                        <div class="mobile-text" *ngIf="col === 'name'">
                            {{ element['type'] }}
                        </div>
                        <div class="mobile-text" *ngIf="col === 'name' && element['last_visited']?.length">
                            {{ element['last_visited'] }}
                        </div>
                        <div class="info-text" *ngIf="col === 'name'">
                            {{ element['date'] }}
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row class="table_data" *matRowDef="let row; columns: displayedColumns" [ngClass]="{pointer: isClickable}" (click)="onClick(row)"></mat-row>
            </mat-table>
            <div class="default-case-mesg" *ngIf="(tableData | userFilter : searchValue)?.length === 0 && tableData?.length > 0 && show_search">
                <div>
                    <div>
                        <mat-icon>no_food</mat-icon>
                    </div>
                    No results found.
                </div>
            </div>
            <div class="default-case-mesg" *ngIf="tableData?.length === 0">
                <div>
                    <div>
                        <mat-icon>no_food</mat-icon>
                    </div>
                    {{ defaultMessage }}
                </div>
            </div>
        </div>
        <mat-card-footer class="footer" *ngIf="(show_search ? (tableData | userFilter : searchValue) : tableData)?.length > limit || config?.totalItems > limit">
            <pagination-controls previousLabel="" nextLabel="" class="table-pagination" (pageChange)="pageChanged($event)" [id]="table_title"></pagination-controls>
        </mat-card-footer>
    </div>
</div>
