import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SuccessFailureDialogComponent } from './../../shared/success-failure-dialog/success-failure-dialog.component';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}
  //pop up dialog
  popup(modal: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = modal.size ? modal.size : '400px';
    dialogConfig.data = modal;
    const dialogRef = this.dialog.open(
      SuccessFailureDialogComponent,
      dialogConfig
    );
    if (modal.autodisable) {
      setTimeout(() => {
        this.dialog.closeAll();
      }, 3000);
    }
  }
}
