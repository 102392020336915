import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../utility/services/data.service';
import { ApiService } from '../../../utility/services/api.service';
import { Location } from '@angular/common';
@Component({
    selector: 'app-customer-feedback',
    templateUrl: './customer-feedback.component.html',
    styleUrls: ['./customer-feedback.component.scss'],
})
export class CustomerFeedbackComponent implements OnInit {
    merchantInfo: any;
    userData: any = 'loading';
    userId: any;
    feedback: any;
    currentRoute: any;
    @Input() visibility: any;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private apiService: ApiService,
        private location: Location
    ) { }

    ngOnInit() {
        this.merchantInfo = this.dataService.getDataFromStore('merchantInfo');
        this.userId = this.route.snapshot.params['id'];
        this.currentRoute = this.router.url;
        let params = { user_id: this.userId };
        this.apiService
            .getData('/v2/customers/customers_feedback', params)
            .subscribe(
                (result: any) => {
                    this.userData = result;
                },
                (err) => {
                    this.userData = {};
                }
            );
    }

    goBack() {
        this.location.back();
    }
}
