<form *ngIf="basicOpenHoursForm" id="basicOpenHours" [formGroup]="basicOpenHoursForm">
	<div class="form-group_heading">Mark open days</div>
	<div class="mb-20">
		<mat-chip-listbox (change)="toggleOpenDays($event)"  multiple>
			<mat-chip-option  *ngFor="let day of days" color="primary" [selected]="basicOpenHoursForm?.get('days').value.indexOf(day) > -1" [value]="day" [id]="day">
			<span class="pretitle" >{{ day }}</span>
			</mat-chip-option>
		</mat-chip-listbox>
		<div class="input-error" *ngIf="basicOpenHoursForm?.get('days')?.errors?.required">
			Mandatory
		</div>
	</div>
	<div id="open-hours" formArrayName="open_slot">
    	<div class="flex-form" *ngFor="let slot of basicOpenHoursFormArr?.controls; let i = index" [formGroupName]="i">
      		
			<mat-form-field appearance="outline" subscriptSizing="dynamic">
				<mat-label>Opens at</mat-label>
				<mat-select formControlName="start">
					<mat-option value="" default>hh:mm aa</mat-option>
					<mat-option *ngFor="let time of start_time_slots"  [value]="time.id" [disabled]="time.id < basicOpenHoursFormArr?.controls[i - 1]?.get('end')?.value">{{ time.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		
			<mat-form-field appearance="outline" subscriptSizing="dynamic">
				<mat-label>Closes at</mat-label>
				<mat-select formControlName="end">
					<mat-option value="" default>hh:mm aa</mat-option>
					<mat-option *ngFor="let time of end_time_slots" [disabled]="!slot.get('start').value || slot.get('start').value >= time.id" [value]="time.id">{{ time.name }}</mat-option>
				</mat-select>
			</mat-form-field>
	
			<button mat-icon-button class="btn-thrive_icon delete-btn" *ngIf="basicOpenHoursFormArr?.controls?.length > 1" (click)="removeTimeSlot(i)">
				<mat-icon>delete</mat-icon>
			</button>
      		
			<mat-error *ngIf="slot?.errors?.startGreater">
				Store closing time cannot be less than store opening time
			</mat-error>

    	</div>
		<div class="link" (click)="addTimeSlot({ start: '', end: '' })">
		+ Add time slots
		</div>
  	</div>
</form>