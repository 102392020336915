<div class="flip-card" id="gradient-chart" *ngIf="chartData?.d1 !== 'loading'" id="flip-card-{{ chartData.id }}" (click)="flip(chartData.id)">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <mat-card appearance="outlined" class="mat-elevation-z3 overview_cards box-shadow-card">
        <div class="rotate-icon">
          <!-- <i class="material-icons" style="color: #5e5e5e;">replay</i> -->
          <img src="assets/img/refresh.svg" alt="rotate" />
        </div>
        <mat-card-header class="block_display">
          <mat-card-title class="overview_title">{{
            chartData.title
            }}</mat-card-title>
          <mat-card-subtitle class="overview_sub_title">
            {{ chartData.value }}
            <p class="overview_p negative_percent" *ngIf="chartData.percent < 0">
              {{ chartData.percent }}%
            </p>
            <p class="overview_p positive_percent" *ngIf="chartData.percent >= 0">
              +{{ chartData.percent }}%
            </p>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content id="{{ chartData.id }}">
          <!-- <chart [options]="options"></chart> -->
          <charts [chartOptions]="chartData.chartOptions"></charts>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flip-card-back">
      <mat-card appearance="outlined" class="mat-elevation-z3 overview_cards box-shadow-card" *ngIf="chartData.source === 'dashboard'">
        <div class="rotate-icon">
          <img src="assets/img/refresh.svg" alt="rotate" />
          <!-- <i class="material-icons" style="color: #5e5e5e;">replay</i> -->
        </div>
        <mat-card-header class="block_display">
          <mat-card-title class="overview_title">This {{ chartData.period_name }}</mat-card-title>
          <mat-card-subtitle class="overview_sub_title">{{
            chartData.this_week
            }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="prev_week_section">
          <mat-card-title class="overview_title">Previous {{ chartData.period_name }}</mat-card-title>
          <mat-card-subtitle class="overview_sub_title">{{
            chartData.prev_week
            }}</mat-card-subtitle>
          <mat-card-subtitle style="margin-top: 44px" class="overview_sub_title overview_p negative_percent" *ngIf="chartData.percent < 0"><span>{{ chartData.percent }}%</span></mat-card-subtitle>
          <mat-card-subtitle style="margin-top: 44px" class="overview_sub_title overview_p positive_percent" *ngIf="chartData.percent >= 0"><span>+{{ chartData.percent }}%</span></mat-card-subtitle>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined" class="mat-elevation-z3 overview_cards box-shadow-card" style="padding-bottom: 10px; padding-top: 20px" *ngIf="chartData.source === 'customers'">
        <mat-card-header class="block_display">
          <mat-card-title class="overview_title">{{
            chartData.title
            }}</mat-card-title>
          <mat-card-subtitle class="overview_sub_title">
            {{ chartData.value }}
          </mat-card-subtitle>
          <!-- <mat-card-subtitle style="margin-top: 37px" class="customer_title">{{chartData.prev_week}}</mat-card-subtitle> -->
          <!-- <mat-card-subtitle class="customer_sub_title">(Previous)</mat-card-subtitle> -->
        </mat-card-header>
        <mat-card-content class="prev_week_section">
          <mat-card-title class="overview_title">Previous</mat-card-title>
          <mat-card-subtitle class="overview_sub_title">{{
            chartData.prev_week
            }}</mat-card-subtitle>
          <mat-card-subtitle style="margin-top: 44px" class="overview_sub_title overview_p negative_percent" *ngIf="chartData.percent < 0">
            <p>{{ chartData.percent }}%</p>
          </mat-card-subtitle>
          <mat-card-subtitle style="margin-top: 44px" class="overview_sub_title overview_p positive_percent" *ngIf="chartData.percent >= 0">
            <p>+{{ chartData.percent }}%</p>
          </mat-card-subtitle>
          <!-- <div style="text-align: center"><button class="deep_dive_button" mat-button>DEEP DIVE</button></div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<app-skeleton-loader [type]="'box'" *ngIf="chartData?.d1 === 'loading'" [details]="{ styles: { height: '280px', width: '100%' } }"></app-skeleton-loader>