<ng-container *ngIf="data.type === 'generate-report-email'">
    <div class="dialog-header">
        <span>{{ data.title }}</span>
        <button mat-icon-button color="primary" class="btn-thrive_icon" [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="dialog-body pt-16 pl-16 pr-16">
        <div class="thrive-toast_info embedded mb-16">
            <span>Info:</span>
            {{ data.description }}
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="always" class="w-100" [class.form-error]="!validEmail && submitted">
            <mat-label>Enter Email</mat-label>
            <input matInput id="email" type="text" [(ngModel)]="email" placeholder="You can enter multiple email addresses separated by a comma" />
        </mat-form-field>
        <div class="has-error" *ngIf="!validEmail && submitted">Please enter a valid email address</div>
    </div>
    <div class="dialog-footer">
        <button (click)="isEmailValid('skip')" class="btn-thrive_secondary" mat-button>Skip</button>
        <button (click)="checkEmails()" class="btn-thrive_primary ml-20" mat-button>Submit</button>
    </div>
</ng-container>
