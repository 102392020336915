import {Injectable} from '@angular/core';

export const dateRange = [
    // { value:'0',  name:'Today'},
    {value: '7', name: 'Past 7 days'},
    {value: '30', name: 'Past 30 days'},
    {value: '90', name: 'Past 90 days'},
    {value: '365', name: 'Last one year'}
    // { value:'custom', name:'Custom'}
];

// export class DataService {
//   public searchValue: any;
// }

@Injectable({
    providedIn: 'root'
})
export class NumberFormatter {
    public nFormatter(num: any) {
        if (num >= 10000000) {
            return (num / 10000000).toFixed(2).replace(/\.0$/, '') + ' Cr';
        }
        if (num >= 100000) {
            return (num / 100000).toFixed(2).replace(/\.0$/, '') + ' L';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(2).replace(/\.0$/, '') + ' K';
        }
        return num;
    }
}

export const customerSegmentColors = {
    New: '#287FB7',
    Regular: '#D85459',
    'At Risk': '#FFCD03',
    Lapse: '#5E335E',
    Lost: '#16A086',
    VIP: '#8DAF1E',
    'Fifth Visit': '#ffa900',
    'Third Visit': '#ffa900',
    Birthday: '#99aad5'
};

//pages not to be included for chain owner
export const notShowForChainOwner = ['upload', '/marketing-material'];

//pages not to be included for non-loyalty merchants
export const notShowForNonloyaltyCustomers = ['/marketing-material'];

//hotjar variable declaration
declare var hj;
export function getHj() {
    let HJ;
    try {
        hj;
    } catch (e) {
        if (e.name == 'ReferenceError') {
            HJ = false;
        }
    }
    return HJ === false ? HJ : hj;
}

//video formats
export const video_formats = [
    'video/3g2',
    'video/3gp',
    'video/3gpp',
    'video/asf',
    'video/avi',
    'video/dat',
    'video/divx',
    'video/dv',
    'video/f4v',
    'video/flv',
    'video/gif',
    'video/m2ts',
    'video/m4v',
    'video/mkv',
    'video/mod',
    'video/mov',
    'video/mp4',
    'video/mpe',
    'video/mpeg',
    'video/mpeg4',
    'video/mpg',
    'video/mts',
    'video/nsv',
    'video/ogm',
    'video/ogv',
    'video/qt',
    'video/tod',
    'video/ts',
    'video/vob',
    'video/wmv'
];

//image formats
export const image_formats = [
    'image/bmp',
    'image/dib',
    'image/gif',
    'image/heic',
    'image/heif',
    'image/iff',
    'image/jfif',
    'image/jp2',
    'image/jpe',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/psd',
    'image/tif',
    'image/tiff',
    'image/wbmp',
    'image/webp',
    'image/xbm'
];
