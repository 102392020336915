import {
  Component,
  Input,
  Output,
  EventEmitter,
  IterableDiffers,
  OnInit,
} from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
@Component({
  selector: 'select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.css'],
})
export class SelectBoxComponent implements OnInit {
  @Input() type: string;
  @Input() optionsList: any;
  @Input() multiple: boolean;
  @Input() isClose: boolean;
  @Input() placeholder: string;
  @Input() showSearch: boolean;
  @Input() floatingLabel: string;
  @Input() position: string;
  @Input() optionalLabel: string;
  @Input() propertyNames: any;
  @Input() isInvalid: boolean;
  @Input() selectedValues: any = [];
  @Output() onSelection = new EventEmitter<string>();
  @Output() clearValue = new EventEmitter<string>();
  showSelectList: boolean = false;
  propertyToSearchFor: any;
  differ: any;
  showClear: boolean = false;
  constructor(differs: IterableDiffers) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.placeholder = this.placeholder ? this.placeholder : '';
    this.propertyToSearchFor = this.propertyNames.name2
      ? [this.propertyNames.name, this.propertyNames.name2]
      : [this.propertyNames.name];
  }

  ngDoCheck() {
    if (
      this.isClose &&
      this.selectedValues !== undefined &&
      this.selectedValues !== ''
    ) {
      this.showClear =
        typeof this.selectedValues === 'object' && isEmpty(this.selectedValues)
          ? false
          : true;
    } else {
      this.showClear = false;
    }
    if (this.multiple) {
      var changes = this.differ.diff(this.selectedValues);
      if (changes) {
        this.optionsList.forEach((option) => {
          let i = this.selectedValues.findIndex((selected) => {
            return selected.id === option.id;
          });
          option['isChecked'] = i > -1 ? true : false;
        });
      }
    }
  }

  toggleSelectList(area: string) {
    this.showSelectList = area === 'inside' ? !this.showSelectList : false;
  }

  selectOption(event: any, option: any, type: string) {
    switch (type) {
      case 'single':
        this.showSelectList = false;
        break;
    }
    this.onSelection.next(option);
  }

  clearData() {
    this.clearValue.next('');
  }
}
