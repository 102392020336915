import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
    selector: 'app-generic-input-model',
    templateUrl: './generic-input-model.component.html',
    styleUrls: ['./generic-input-model.component.scss'],
})
export class GenericInputModelComponent implements OnInit {
    email: any;
    submitted: boolean = false;
    validEmail: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<GenericInputModelComponent>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    ngOnInit() { }
    checkEmail(email) {
        let trimedEmail = email.trim();
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(trimedEmail).toLowerCase());
    }
    checkEmails() {
        this.submitted = true;
        this.validEmail = false;
        if (this.email) {
            let emailArray = this.email.split(',');
            let invEmails = '';
            for (let i = 0; i <= emailArray.length - 1; i++) {
                if (this.checkEmail(emailArray[i])) {
                    this.validEmail = true;
                    this.isEmailValid(emailArray);
                } else {
                    invEmails += emailArray[i] + '\n';
                }
            }
            if (invEmails != '') {
                this.validEmail = false;
            }
        } else {
            this.validEmail = false;
        }
    }
    isEmailValid(data) {
        this.dialogRef.close(data);
    }

    onCancel() {
        this.dialogRef.close(false);
    }
}
