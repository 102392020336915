import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    base_url: string = environment.RAILS_API;
    constructor(private http: HttpClient) { }

    getData(...args: any) {
        if (args[1]) {
            let params = args[1];
            return this.http.get(this.base_url + args[0], { params });
        } else if (args[2]) {
            return this.http.get(this.base_url + args[0], args[2]);
        } else {
            return this.http.get(this.base_url + args[0]);
        }
    }

    getDataFromExt(api: string, params: any) {
        return params ? this.http.get(api, { params }) : this.http.get(api);
    }

    postData(api: string, data: any) {
        return this.http.post(this.base_url + api, data);
    }

    downloadData(api) {
        return this.http.get(this.base_url + api, { responseType: 'blob' });
    }

    putData(api: string, data: any) {
        return this.http.put(this.base_url + api, data);
    }

    patchData(api: string, data?: any) {
        return this.http.patch(this.base_url + api, data ? data : {});
    }

    deleteData(api: string, params: any) {
        return params ? this.http.delete(this.base_url + api, { params }) : this.http.delete(this.base_url + api);
    }
    verifyGST(gst): Observable<any> {
        return this.http.post(`${this.base_url}/v4/merchants/verify_gst?gst_number=${gst}`, {});
    }
    verifyPAN(pan): Observable<any> {
        return this.http.post(`${this.base_url}/v4/merchants/verify_pan`, { pan_number: pan });
    }
    verifyBankDetails(acctNo, ifsc): Observable<any> {
        return this.http.post(`${this.base_url}/v4/merchants/verify_bank_details?account_number=${acctNo}&ifsc_code=${ifsc}`, {});
    }
}
