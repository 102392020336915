import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'hide_show_fied_pipe',
  pure: false,
})
export class HideShowFieldPipe implements PipeTransform {
  transform(field: any[], filter: Object): any {
    if (!field || !filter) {
      return field;
    }
    return field.filter((key) => key.show_field === true);
  }
}
