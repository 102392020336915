const ZOHO_KB_ARTICLES = {
    'item-availability':{
        categoryId:'461440000032754028',
        articleId:'461440000033088432'
    },
    // '/item-availability/variations':{
    //     categoryId:'461440000032754028',
    //     articleId:'461440000033088432'
    // },
    // '/item-availability/add-ons':{
    //     categoryId:'461440000032754028',
    //     articleId:'461440000033088432'
    // },
    'menu-management':{
        categoryId:'461440000032754039',
        articleId:null
    },
    // '/menu-management/variations':{
    //     categoryId:'461440000032754039',
    //     articleId:null
    // },
    // '/menu-management/addons':{
    //     categoryId:'461440000032754039',
    //     articleId:null
    // },
    // '/menu-management/upload-images':{
    //     categoryId:'461440000032754039',
    //     articleId:null
    // },
    // '/menu-management/upload-menu':{
    //     categoryId:'461440000032754039',
    //     articleId:null
    // },
    '/item-charges':{//to be discussed
    },
    // '/item-charges/edit/370':{//to be discussed

    // },
    // '/item-charges/new':{//to be discussed

    // },
    'offers':{
        categoryId:'461440000032224267',
        articleId:null
    },
    // '/offers/41514':{//to be figured

    // },
    // '/offers/select-offer':{
    //     categoryId:'461440000032224267',
    //     articleId:null
    // },
    '/banners':{//to be discussed

    },
    // '/banners/edit/2381':{//to be discussed

    // },
    // '/banners/new':{//to be discussed

    // },
    '/past-order':{//to be discussed

    },
    // '/past-order/36161999/details':{//to be discussed

    // },
    'customers':{//to be discussed
        categoryId:'461440000032224190',
        articleId:null
    },
    // '/customers/overview':{//to be discussed

    // },
    '/customers/segmentation':{
        categoryId:'461440000032506167',
        articleId:null
    },
    '/customers/tags':{
        categoryId:'461440000032506178',
        articleId:null
    },
    '/customers/leads':{
        categoryId:'461440000032506189',
        articleId:'461440000033146576'
    },
    '/customers/activity':{
        categoryId:'461440000032754113',
        articleId:'461440000033146752'
    },
    // '/customers/demographics':{//to be discussed

    // },
    // '/customers/settings':{//to be discussed

    // },
    // '/customers/list':{//to be discussed

    // },
    'business-intelligence':{
        categoryId:'461440000032224201',
        articleId:null
    },
    'templates':{
        categoryId:'461440000032506276',
        articleId:null
    },
    'campaigns':{
        categoryId:'461440000032224289',
        articleId:null
    },
    'auto-engage':{
        categoryId:'461440000032224300',
        articleId:'461440000033177165'
    },
    'facebook-ad':{
        categoryId:'461440000032224322',
        articleId:null
    },
    'loyalty':{
        categoryId:'461440000032224223',
        articleId:null
    },
    'feedback':{
        categoryId:'461440000032224234',
        articleId:null
    },
    'generate-report':{
        categoryId:'461440000032224245',
        articleId:null
    },
    'default':{
        categoryId:null,
        articleId:null
    }
};

//customers articles can be bifurcated
export function getZohoKBArticle(url:string,section:string){
    if(ZOHO_KB_ARTICLES[url]){
        return ZOHO_KB_ARTICLES[url];
    }else if(ZOHO_KB_ARTICLES[section]){
        return ZOHO_KB_ARTICLES[section];
    }else{
        return ZOHO_KB_ARTICLES['default'];
    }
}
