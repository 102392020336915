<div id="customer-feedback" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span (click)="goBack()">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Customer Feedback</h2>
            <h4 class="header" *ngIf="visibility?.user_info || !visibility">
                <div>
                    <mat-icon class="outline">person</mat-icon>
                    <span>{{ userData?.name }}</span>
                </div>
                |
                <div>
                    <mat-icon class="outline">call</mat-icon>
                    <span>{{ userData?.mobile }}</span>
                </div>
            </h4>
        </div>
    </div>
    <div class="thrive-card no-hover">
        <!-- accordion -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let feedback of userData?.user_feedbacks; let idx = index; first as isFirst" [expanded]="isFirst">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ feedback?.date }} | {{ feedback?.merchant_name + ', ' + feedback?.merchant_location }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="questions">
                    <div class="left-box">
                        <div class="subtitle mb-20">CSAT QUESTIONS</div>
                        <div *ngFor="let question of feedback?.csat; let i = index" class="mb-16">
                            <div class="subbase normal">{{ question?.question_text }}</div>
                            <app-mat-star-rating class="ml-20" [rating]="question?.rating" [selected]="feedback?.icon_type?.selected" [default]="feedback?.icon_type?.default" [starCount]="5"></app-mat-star-rating>
                        </div>
                        <div *ngIf="feedback?.avg_poor_csat_response && feedback?.avg_poor_csat_response?.length > 0" class="mb-16">
                            <div class="subbase normal">Negative Experience Response</div>
                            <div class="subbase ml-20">{{ feedback?.avg_poor_csat_response }}</div>
                        </div>

                        <mat-divider class="mt-20 pb-20"></mat-divider>

                        <div *ngIf="feedback?.custom && feedback?.custom?.length > 0">
                            <div class="subtitle mb-20">POLL BASED</div>
                            <div *ngFor="let question of feedback?.custom; let i = index" class="mb-16">
                                <div class="subbase normal">Q{{ i + 1 }}{{ '. ' + question?.question_text }}</div>
                                <div *ngIf="question?.question_type === 'mcq'">
                                    <div class="subbase ml-20" *ngIf="question?.response?.length > 0">
                                        {{ question?.response }}
                                    </div>
                                    <div class="subbase ml-20" *ngIf="question?.response?.length === 0">--</div>
                                </div>
                                <div class="subbase normal" *ngIf="question?.comment?.length">Follow up question?</div>
                                <div class="subbase ml-20" *ngIf="(question?.question_type === 'mcq' && question?.comment?.length) || question?.question_type === 'text'">
                                    <span *ngIf="question?.question_type === 'mcq'">{{ question?.comment }}</span>
                                    <span *ngIf="question?.question_type === 'text'">{{ question?.response }}</span>
                                </div>
                            </div>
                        </div>

                        <mat-divider class="mt-20 pb-20" *ngIf="feedback?.feedback_comment && feedback?.feedback_comment?.length > 0"></mat-divider>

                        <div *ngIf="feedback?.feedback_comment && feedback?.feedback_comment?.length > 0" class="mb-16">
                            <div class="subbase normal">Additional Comments Box</div>
                            <div class="subbase ml-20">{{ feedback?.feedback_comment }}</div>
                        </div>
                    </div>
                    <div class="right-box text-right" style="border-left: 1px solid rgba(0, 0, 0, 0.12)">
                        <div class="rating-box mb-20">
                            <div class="basetitle">CSAT Average Score</div>
                            <div class="avg-rating">
                                <span class="title_regular">{{ feedback?.user_csat }}</span> out of 5
                            </div>
                            <div class="pretitle normal">{{ feedback?.csat_category }}</div>
                        </div>
                        <mat-divider class="mt-20 pb-20"></mat-divider>
                        <div class="rating-box mb-20">
                            <div class="basetitle">Avg. NPS Rating</div>
                            <div class="avg-rating">
                                <span class="title_regular">{{ feedback?.user_nps }}</span> out of 10
                            </div>
                            <div class="pretitle normal">{{ feedback?.nps_category }}</div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="default-case-mesg" *ngIf="!userData?.user_feedbacks || (userData?.user_feedbacks && !userData?.user_feedbacks?.length)">
            <div>
                <div>
                    <mat-icon>block</mat-icon>
                </div>
                <span class="basetitle normal">No Feedback Available</span>
            </div>
        </div>
        <!-- accordion end -->
    </div>
</div>
