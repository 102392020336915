<div [ngSwitch]="type" id="skeletonLoaderContainer">
  <!-- the same view can be shown in more than one case -->
  <!-- table -->
  <div *ngSwitchCase="'table'">
    <mat-card appearance="outlined" class="mat-elevation-z3 dashboard_table">
      <mat-card-header *ngIf="!details.hideTitle">
        <mat-card-title class="table_title">{{
          details.tableTitle
        }}</mat-card-title>
        <div class="search-skeleton" *ngIf="showSearch">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{ 'border-radius': '25px', height: '25px' }"
          ></ngx-skeleton-loader>
        </div>
      </mat-card-header>
      <mat-card-content>
        <mat-table class="mat-elevation-z5" [dataSource]="tableData">
          <ng-container
            *ngFor="let col of details.columns"
            matColumnDef="{{ col }}"
          >
            <mat-header-cell *matHeaderCellDef>
              {{ col | sentencecase : 'remove_' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                appearance="line"
                style="width: 100%"
                [theme]="{ margin: '0px 10px 10px 10px' }"
              ></ngx-skeleton-loader>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="details.columns"></mat-header-row>
          <mat-row
            class="table_data"
            *matRowDef="let row; columns: details.columns"
          ></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngSwitchCase="'box'">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="details.styles"
    ></ngx-skeleton-loader>
  </div>
  <div *ngSwitchCase="'circle'">
    <ngx-skeleton-loader
      appearance="circle"
      [theme]="details.styles"
    ></ngx-skeleton-loader>
  </div>
  <!--default case when there are no matches -->
  <!-- <some-element *ngSwitchDefault>...</some-element> -->
</div>
