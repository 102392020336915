import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import clevertap from 'clevertap-web-sdk';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

clevertap.init(environment.CLEVER_TAP, 'eu1');
clevertap.spa = true;

if (environment.production) {
    enableProdMode();
}

Sentry.init({
  dsn: "https://2cfcccf69bf04e20b75bb5ffab851c35@o69967.ingest.us.sentry.io/6408420",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
  environment: environment.SENTRY_ENV, // Adjust according to your environment settings 
});  
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));