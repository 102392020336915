import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit,OnChanges } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn, UntypedFormGroup, Validators, UntypedFormArray, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

export function checkForStartTimeLessThanEndTime(
  control: UntypedFormGroup
): { [key: string]: boolean } | null {
  if (control.value.start && control.value.end) {
    let start: string = control.value.start + ':00';
    let end: string = control.value.end + ':00';
    if (start >= end) {
      return { startGreater: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function checkForOverLap(): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: boolean } | null => {
    let valid: boolean = true;
    let end: string = formArray.controls[0].value['end'];
    for (let i: number = 1; i < formArray.controls.length; i++) {
      if (formArray.controls[i].value['start'] < end) {
        valid = false;
        break;
      } else {
        end = formArray.controls[i].value['end'];
      }
    }
    return valid ? null : { timeOverlapping: true };
  };
}

export const start_time_slots = [
  { id: '00:00', name: '12:00 AM' },
  { id: '00:30', name: '12:30 AM' },
  { id: '01:00', name: '01:00 AM' },
  { id: '01:30', name: '01:30 AM' },
  { id: '02:00', name: '02:00 AM' },
  { id: '02:30', name: '02:30 AM' },
  { id: '03:00', name: '03:00 AM' },
  { id: '03:30', name: '03:30 AM' },
  { id: '04:00', name: '04:00 AM' },
  { id: '04:30', name: '04:30 AM' },
  { id: '05:00', name: '05:00 AM' },
  { id: '05:30', name: '05:30 AM' },
  { id: '06:00', name: '06:00 AM' },
  { id: '06:30', name: '06:30 AM' },
  { id: '07:00', name: '07:00 AM' },
  { id: '07:30', name: '07:30 AM' },
  { id: '08:00', name: '08:00 AM' },
  { id: '08:30', name: '08:30 AM' },
  { id: '09:00', name: '09:00 AM' },
  { id: '09:30', name: '09:30 AM' },
  { id: '10:00', name: '10:00 AM' },
  { id: '10:30', name: '10:30 AM' },
  { id: '11:00', name: '11:00 AM' },
  { id: '11:30', name: '11:30 AM' },
  { id: '12:00', name: '12:00 PM' },
  { id: '12:30', name: '12:30 PM' },
  { id: '13:00', name: '01:00 PM' },
  { id: '13:30', name: '01:30 PM' },
  { id: '14:00', name: '02:00 PM' },
  { id: '14:30', name: '02:30 PM' },
  { id: '15:00', name: '03:00 PM' },
  { id: '15:30', name: '03:30 PM' },
  { id: '16:00', name: '04:00 PM' },
  { id: '16:30', name: '04:30 PM' },
  { id: '17:00', name: '05:00 PM' },
  { id: '17:30', name: '05:30 PM' },
  { id: '18:00', name: '06:00 PM' },
  { id: '18:30', name: '06:30 PM' },
  { id: '19:00', name: '07:00 PM' },
  { id: '19:30', name: '07:30 PM' },
  { id: '20:00', name: '08:00 PM' },
  { id: '20:30', name: '08:30 PM' },
  { id: '21:00', name: '09:00 PM' },
  { id: '21:30', name: '09:30 PM' },
  { id: '22:00', name: '10:00 PM' },
  { id: '22:30', name: '10:30 PM' },
  { id: '23:00', name: '11:00 PM' },
  { id: '23:30', name: '11:30 PM' },
];
export const end_time_slots = [
  { id: '00:30', name: '12:30 AM' },
  { id: '01:00', name: '01:00 AM' },
  { id: '01:30', name: '01:30 AM' },
  { id: '02:00', name: '02:00 AM' },
  { id: '02:30', name: '02:30 AM' },
  { id: '03:00', name: '03:00 AM' },
  { id: '03:30', name: '03:30 AM' },
  { id: '04:00', name: '04:00 AM' },
  { id: '04:30', name: '04:30 AM' },
  { id: '05:00', name: '05:00 AM' },
  { id: '05:30', name: '05:30 AM' },
  { id: '06:00', name: '06:00 AM' },
  { id: '06:30', name: '06:30 AM' },
  { id: '07:00', name: '07:00 AM' },
  { id: '07:30', name: '07:30 AM' },
  { id: '08:00', name: '08:00 AM' },
  { id: '08:30', name: '08:30 AM' },
  { id: '09:00', name: '09:00 AM' },
  { id: '09:30', name: '09:30 AM' },
  { id: '10:00', name: '10:00 AM' },
  { id: '10:30', name: '10:30 AM' },
  { id: '11:00', name: '11:00 AM' },
  { id: '11:30', name: '11:30 AM' },
  { id: '12:00', name: '12:00 PM' },
  { id: '12:30', name: '12:30 PM' },
  { id: '13:00', name: '01:00 PM' },
  { id: '13:30', name: '01:30 PM' },
  { id: '14:00', name: '02:00 PM' },
  { id: '14:30', name: '02:30 PM' },
  { id: '15:00', name: '03:00 PM' },
  { id: '15:30', name: '03:30 PM' },
  { id: '16:00', name: '04:00 PM' },
  { id: '16:30', name: '04:30 PM' },
  { id: '17:00', name: '05:00 PM' },
  { id: '17:30', name: '05:30 PM' },
  { id: '18:00', name: '06:00 PM' },
  { id: '18:30', name: '06:30 PM' },
  { id: '19:00', name: '07:00 PM' },
  { id: '19:30', name: '07:30 PM' },
  { id: '20:00', name: '08:00 PM' },
  { id: '20:30', name: '08:30 PM' },
  { id: '21:00', name: '09:00 PM' },
  { id: '21:30', name: '09:30 PM' },
  { id: '22:00', name: '10:00 PM' },
  { id: '22:30', name: '10:30 PM' },
  { id: '23:00', name: '11:00 PM' },
  { id: '23:30', name: '11:30 PM' },
  { id: '24:00', name: '11:59 PM' },
];

@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['../open-hours.component.scss'],
  standalone: true,
  imports: [ CommonModule, MatCheckboxModule, MatSelectModule, MatButtonModule, MatChipsModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule ]
})
export class AdvanceSettingsComponent implements OnInit, OnChanges {
  @Input() default_time_slots: any;
  @Output() submit = new EventEmitter<any>();
  constructor(private fb: UntypedFormBuilder) {}
  days: any = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  start_time_slots: any = start_time_slots;
  end_time_slots: any = end_time_slots;
  openHoursForm: UntypedFormGroup;
  // getter for open hours
  get openHoursFormArr(): UntypedFormArray {
    return this.openHoursForm.get('open_hours') as UntypedFormArray;
  }
  // add days to open hour form
  addAWorkingDay(day: any) {
    this.openHoursFormArr.push(
      this.fb.group({
        day: [day.day],
        is_open: [!day.closed],
        open_slot: this.fb.array(
          [
            this.fb.group(
              {
                start: [
                  day.open_slot[0] && day.open_slot[0].start
                    ? day.open_slot[0].start
                    : '',
                  [Validators.required],
                ],
                end: [
                  day.open_slot[0] && day.open_slot[0].end
                    ? day.open_slot[0].end
                    : '',
                  [Validators.required],
                ],
              },
              { validator: checkForStartTimeLessThanEndTime }
            ),
          ],
          checkForOverLap()
        ),
      })
    );
    if (day.open_slot.length > 1) {
      let idx: number = this.openHoursFormArr.value.length - 1;
      for (let i: number = 1; i < day.open_slot.length; i++) {
        this.addMoreSlotsInADay(
          this.openHoursFormArr.controls[idx].get('open_slot'),
          day.open_slot[i]
        );
      }
    }
  }
  //more time slots in a single day
  addMoreSlotsInADay(control: any, value: any) {
    control.push(
      this.fb.group(
        {
          start: [value.start, [Validators.required]],
          end: [value.end, [Validators.required]],
        },
        { validator: checkForStartTimeLessThanEndTime }
      )
    );
  }
  //remove time slot from a day
  removeTimeSlotFromADay(control: any, index: number) {
    control.removeAt(index);
  }
  toggleOpenDays(event: any, day: any) {
    for (let i: number = 0; i < day.get('open_slot').controls.length; i++) {
      if (event.target.checked) {
        day
          .get('open_slot')
          .controls[i].get('start')
          .setValidators([Validators.required]);
        day
          .get('open_slot')
          .controls[i].get('end')
          .setValidators([Validators.required]);
      } else {
        day.get('open_slot').controls[i].get('start').clearValidators();
        day.get('open_slot').controls[i].get('end').clearValidators();
      }
      day.get('open_slot').controls[i].get('start').updateValueAndValidity();
      day.get('open_slot').controls[i].get('end').updateValueAndValidity();
    }
  }
  initialise() {
    for (let i: number = 0; i < this.default_time_slots.length; i++) {
      this.addAWorkingDay(this.default_time_slots[i]);
    }
  }
  ngOnInit() {}
  ngOnChanges() {
    this.openHoursForm = this.fb.group({
      open_hours: this.fb.array([]),
    });
    this.initialise();
  }
  submitForm() {
    this.submit.next(this.openHoursForm);
  }
}
