import {Component, OnChanges, Input, Inject} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
@Component({
    selector: 'app-customer-journey',
    templateUrl: './customer-journey.component.html',
    styleUrls: ['./customer-journey.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule,MatTooltipModule]
})
export class CustomerJourneyComponent implements OnChanges {
    cardValue: any;
    // noOfCards: number = 3;
    @Input() cardData: any;
    constructor(@Inject(DOCUMENT) private doc: any) {}

    ngOnChanges() {
        if (this.cardData && this.cardData.card_detail) {
            this.cardValue = this.cardData.card_detail;
        } else {
            this.cardValue = this.cardData;
        }
        // this.noOfCards = this.cardData && this.cardData.card_name && this.cardData.card_name.length ? this.cardData.card_name.length : 3;
        // this.doc.documentElement.style.setProperty('--noOfCards', this.noOfCards);
        // console.log(this.noOfCards);
    }
}
