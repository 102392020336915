import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {OutletSelectorComponent} from '../../shared/outlet-selector/outlet-selector.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {WalletHeaderInfoComponent} from '../../shared/wallet-header-info/wallet-header-info.component';
import {ApiService} from '../../../utility/services/api.service';
import {DataService} from '../../../utility/services/data.service';
import {WindowRef} from '../../../utility/services/window.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {getZohoKBArticle} from './zoho-hc-asap.model';
import {MixpanelEvents, MixpanelService} from '../../shared/services/mixpanel.service';

@Component({
    selector: 'app-navigation-header',
    templateUrl: './navigation-header.component.html',
    styleUrls: ['./navigation-header.component.scss'],
    imports: [OutletSelectorComponent, WalletHeaderInfoComponent, MatToolbarModule, MatIconModule, CommonModule, MatMenuModule, MatTooltipModule],
    standalone: true
})
export class NavigationHeaderComponent implements OnInit {
    merchant: any;
    isOutletSelectionVisible = false;
    @Input() isExpanded: boolean = false;

    @Output() toggleMenuEvent = new EventEmitter<void>();

    constructor(private apiService: ApiService, public dataService: DataService, private winRef: WindowRef, private router: Router, private trackingService: MixpanelService) {}

    ngOnInit(): void {
        this.merchant = this.dataService.getDataFromStore('merchantInfo');
        this.apiService.getData('/v4/wallets/available_balance', null).subscribe((response: any) => {
            this.dataService.setData('wallet_balance', response.available_balance);
        });
    }

    openHelpPopup() {
        this.winRef.nativeWindow.ZohoHCAsap.Actions.Open();
        this.trackingService.trackEvents(MixpanelEvents.helpPopUp);
        let zohoKbArticleObj = getZohoKBArticle(this.router.url, this.router.url.split('/')[1]);
        if (zohoKbArticleObj.categoryId) {
            this.winRef.nativeWindow.ZohoHCAsap.kbArticles.list({categoryId: zohoKbArticleObj.categoryId});
        }
        if (zohoKbArticleObj.articleId) {
            this.winRef.nativeWindow.ZohoHCAsap.kbArticles.view({articleId: zohoKbArticleObj.articleId});
        }
    }

    openWhatIsNew() {
        // this.winRef.nativeWindow.faqbotAnnouncementStart(1);
    }

    toggleMenu() {
        this.toggleMenuEvent.emit();
    }

    topNavigation(section: string) {
        switch (section) {
            case 'marketing-material':
                this.router.navigateByUrl('/marketing-material');
                break;
            case 'contacts':
            case 'transactions':
                this.router.navigateByUrl('/upload/' + section);
                break;
        }
    }
    openChat(){
        window.open('https://wa.me/919833485073',"_blank")
    }
}
