import { MatButtonModule } from '@angular/material/button';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterModule,ActivatedRoute } from '@angular/router';
import { DataService } from '../../../utility/services/data.service';
import { ApiService } from '../../../utility/services/api.service';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { OutletSelectorComponent } from '../../shared/outlet-selector/outlet-selector.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { NavigationHeaderComponent } from '../navigation-header/navigation-header.component';
import { SharedModule } from '../../../shared/shared.module';
import { WalletHeaderInfoComponent } from '../../shared/wallet-header-info/wallet-header-info.component';
import { StatusTmsComponent } from '../../shared/tms-plan/status-tms/status-tms.component';
import { menuItems } from './menu';
import { WindowRef } from '../../../utility/services/window.service';
import { ToasterService } from '../../shared/toaster/toaster.service';
import { ToasterContainerComponent } from '../../shared/toaster/toaster-container/toaster-container.component';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { MixpanelEvents,MixpanelService} from '../../../views/shared/services/mixpanel.service';
import { HomeLoaderComponent } from '../../shared/home-loader/home-loader.component';
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    imports: [
        CommonModule,
        OutletSelectorComponent,
        WalletHeaderInfoComponent,
        ToasterContainerComponent,
        MatSidenavModule,
        MatListModule,
        NavigationHeaderComponent,
        StatusTmsComponent,
        SharedModule,
        RouterModule,
        MatSlideToggleModule,
        HomeLoaderComponent
    ],
    standalone: true,
})
export class SidenavComponent implements OnInit, OnDestroy {
    merchant: any;
    currentUrl: string;
    previousUrl: string
    isTouchDevice: boolean = window.innerWidth < 810;
    isExpanded = this.isTouchDevice ? true : false;
    selectedHoverMenu: string;
    menuItems = menuItems;
    showTooltip = false;
    show_banner: any = [
        '/item-availability/variations',
        '/item-availability/categories',
        '/item-availability/add-ons',
    ];
    timeInterval: Subscription;
    innerWidth:any;
    constructor(
        private router: Router,
        public dataService: DataService,
        private dashboardService: DashboardService,
        private winRef: WindowRef,
        private toaster: ToasterService,
        private apiService: ApiService,
        public dialog: MatDialog,
        private mixpanelService: MixpanelService,
    ) {
        this.innerWidth = window.innerWidth;
     }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isTouchDevice = window.innerWidth < 810;
        this.isExpanded = this.isTouchDevice ? true : false;
    }
    notification: Array<{ id: number, type: string, title: string, body: string; }> = [];
    status: any = {
        'Notifications::Templates::Approved': 'success',
        'Notifications::Templates::Disabled': 'error',
        'Notifications::Templates::Paused': 'warning',
        'Notifications::Templates::Rejected': 'error',
    };

    ngOnInit() {
        this.currentUrl = this.router.url;
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });
        this.dashboardService.onReloadUpdateConfig(false);
        this.initialise();
        // pull notification from backend
        let obj = {};
        this.timeInterval = interval(300000).pipe(startWith(0), switchMap(() => this.apiService.getData('/v4/notifications', null))).subscribe((response: any) => {
            // will add this later
            // .push(…new Set[array])
            // or we can start using lodash
            this.notification = [];
            let noty = response.notifications;
            for (let i = 0; i < noty.length; ++i) {
                this.notification.push(
                    {
                        "id": noty[i].id,
                        "type": this.status[noty[i].type],
                        "title": "",
                        "body": noty[i].message,
                    }
                );
                // check unique notification via ID
                if (!obj[this.notification[i].id]) {
                    obj[this.notification[i].id] = true;
                    this.showToaster(this.notification[i]);
                }
            }
        }, (error: any) => { });
    }
    ngOnDestroy(): void {
        // clear all notification on component destroy
        this.toaster.clearAll();
    }
    // show notification
    showToaster(notiy: any) {
        this.toaster.show(notiy.id, notiy.type, notiy.title, notiy.body);
    }

    openDetailPopup() {
        const dialog_ref = this.dialog.open(TrialDetailsDialog, {
            width: window.innerWidth < 768 ? '90vw' : '45vw',
            panelClass: ['thrive-dialog','sm-full_width_dailog']
        });
        dialog_ref.afterClosed().subscribe((result: any) => {
            if (result) {
                this.enableFreeTrial(result);
            }
        });
    }

    enableFreeTrial(result) {
        if (result) {
            this.apiService.postData('/v4/merchants/toggle_free_plan', null).subscribe(
                () => {
                    this.mixpanelService.trackEvents(MixpanelEvents.freeTrialEnableViaToggle);
                    Swal.fire('Free Trial activated successfully!', 'Please enjoy the features for 30 days!', 'success');
                    this.merchant.show_free_trial_toggle = false;
                },
                () => Swal.fire('Error', 'Free Trial not activated! Please try again!', 'error')
            );
        }
    }

    initialise() {
        this.dataService.isLocalStorageUpdated.subscribe((data: any) => {
            if (data) {
                this.merchant = this.dataService.getDataFromStore('merchantInfo');
                if (this.merchant) {
                    this.menuItems[4].display = this.merchant.sales_intelligence_active;
                    this.menuItems[3].display = this.merchant.role === 'ChainOwner' ? true : false;
                    this.menuItems[1].display = this.merchant.ordering_types !== null ? true : false;
                    if (this.menuItems[1].display && this.merchant.role === 'ChainOwner') {
                        this.menuItems[1]['sub_menu'][1].display = false;
                        this.menuItems[1]['sub_menu'][2].display = false;
                        this.menuItems[1]['sub_menu'][3].display = false;
                    }
                    this.merchant.thrive_pos_integrated ? (this.menuItems[1]['sub_menu'][1].display = false) : '';
                    this.merchant.menu_created ? (this.menuItems[1]['sub_menu'][2].link = '/menu-management/categories') : (this.menuItems[1]['sub_menu'][2].link = '/menu-management/upload-menu');
                    this.menuItems[1]['sub_menu'][5].display = this.merchant.has_brands;
                }
            }
        });
        this.apiService.postData('/v4/partoo/generate_auth_token', null).subscribe(
            (response: any) => {
                this.menuItems[5].sub_menu[4].display = true;
            }
        );
    }

    toggleMenu() {
        this.isExpanded = !this.isExpanded;
        this.menuItems[1]['show_drop_down'] = false;
        this.menuItems[5]['show_drop_down'] = false;
        this.selectedHoverMenu = '';
    }

    navigateTo(name) {
        if ((name == 'Online ordering' || name == 'Marketing') && this.isTouchDevice) {
            let selectedMenu = this.menuItems.filter((menu) => menu.name == name)[0];
            selectedMenu.show_drop_down = !selectedMenu.show_drop_down;
        } else if (name == 'Logout') {
            this.dataService.logout();
        } else {
            this.selectedHoverMenu = (name == 'Online ordering' || name == 'Marketing') ? name : '';
            this.closeExpandedSidebar();
            this.showTooltip = true;
        }
    }

    closeExpandedSidebar() {
        this.menuItems.forEach(menu => menu.show_drop_down = false);
    }

    subMenuSelectedLink(name: string) {
        let state = false;
        if (name === 'Online ordering') {
            state =
                this.currentUrl.includes('/thrive-metrics') ||
                this.currentUrl.includes('/item-availability') ||
                this.currentUrl.includes('/menu-management') ||
                this.currentUrl.includes('/item-charges') ||
                this.currentUrl.includes('/offers') ||
                this.currentUrl.includes('/banners') ||
                this.currentUrl.includes('/past-order');
        }
        if (name === 'Marketing') {
            state =
                this.currentUrl.includes('/templates') ||
                this.currentUrl.includes('/campaigns') ||
                this.currentUrl.includes('/auto-engage') ||
                this.currentUrl.includes('/referrals') ||
                this.currentUrl.includes('/facebook-ad') ||
                this.currentUrl.includes('/online-presence');
        }
        return state;
    }

    openWhatIsNew() {
        // this.winRef.nativeWindow.faqbotAnnouncementStart(1);
    }

    topNavigation(section: string) {
        this.closeExpandedSidebar();
        switch (section) {
            case 'marketing-material':
                this.router.navigateByUrl('/marketing-material');
            break;
            case 'contacts':
            case 'transactions':
                this.router.navigateByUrl('/upload/' + section);
            break;
            // case 'whatsapp':
            //     window.open('https://wa.me/919833485073',"_blank");
            // break;
        }
    }

}

@Component({
    selector: 'trial-details-dialog',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './trial-details-dialog.html',
    styleUrls: ['./trial-details-dialog.scss']
})

export class TrialDetailsDialog {
    constructor(public dialogRef: MatDialogRef<TrialDetailsDialog>) { }
    enableTrial() {
        this.dialogRef.close(true);
    }
}
