import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class MerchantFilterPipe implements PipeTransform {
  output: any;
  checkForExistence(obj, properties, keyword) {
    let bool = false;
    properties.forEach((propName) => {
      bool =
        bool ||
        (obj[propName] &&
          obj[propName].toLowerCase().includes(keyword.toLowerCase()));
    });
    return bool;
  }
  transform(list: any, keyword: any, ...args: any[]): any[] {
    this.output = [];
    if (keyword && keyword.trim().length && list.length) {
      if (args && args[0][0]) {
        for (let i = 0; i < list.length; i++) {
          let exists = this.checkForExistence(list[i], args[0], keyword);
          if (exists) {
            this.output.push(list[i]);
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].toLowerCase().includes(keyword.toLowerCase())) {
            this.output.push(list[i]);
          }
        }
      }
    } else {
      this.output = list;
    }
    // // this.pagedData(this.output, offset);
    return this.output;
  }
}
