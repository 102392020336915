import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MixpanelEvents, MixpanelService, mixpanelLogin, mixpanelLogout } from '../../views/shared/services/mixpanel.service';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private data = new BehaviorSubject('');
  private feedbackData = new BehaviorSubject('');
  private authentication = new BehaviorSubject('');
  private brand = new BehaviorSubject('');
  private search_value = new BehaviorSubject('');
  private item_availability = new BehaviorSubject('');
  private menuSearchOption = new BehaviorSubject('');
  private localStorageUpdated = new BehaviorSubject('');
  private wallet_balance = new BehaviorSubject(0);
  private freeTrialDetails = new BehaviorSubject('');
  private session_storage: Storage;
  currentData = this.data.asObservable();
  auth = this.authentication.asObservable();
  feeddbackData = this.feedbackData.asObservable();
  isBrandChanged = this.brand.asObservable();
  isSearchValue = this.search_value.asObservable();
  isItemAvailability = this.item_availability.asObservable();
  isMenuSearchOption = this.menuSearchOption.asObservable();
  isLocalStorageUpdated = this.localStorageUpdated.asObservable();
  wallet_balance$ = this.wallet_balance.asObservable();
  refresh_variable = this.localStorageUpdated.asObservable();
  freeTrial = this.freeTrialDetails.asObservable();
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private injector: Injector
  ) {
    this.session_storage = sessionStorage;
  }
  setData(key: string, value: any) {
    this[key].next(value);
  }

  //set data to local storage
  setDataInStore(key: string, value: any) {
    let data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    localStorage.setItem(key, data);
  }

  //get data from local storage
  getDataFromStore(key: string) {
    if (localStorage.getItem(key)) {
      try {
        let data = decodeURIComponent(
          escape(window.atob(localStorage.getItem(key)))
        );
        return JSON.parse(data);
      } catch (e) {
        localStorage.clear();
        return false;
      }
    } else {
      return false;
    }
  }

  // logout
  logout(notShowDialog?: boolean) {
    if (!notShowDialog) {
      Swal.fire({
        title: 'Are you sure you want to logout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#580D7E',
        cancelButtonColor: '#B95000',
        confirmButtonText: 'Confirm'
      }).then((result) => {
        if (result.isConfirmed) {
          this.hitLogoutApi(true);
        }
      });
    } else {
      this.hitLogoutApi(false);
    }
  }

  hitLogoutApi(value: boolean) {
    this.apiService.postData('/v3/merchant_users/logout', null).subscribe((response: any) => {
      this.clearLocalstorage();
      if (value) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('You have logged out successfully.', '', config);
      }
      this.injector.get(MixpanelService).trackEvents(MixpanelEvents.logout, {
        source: 'business dashboard',
        user_id: this.getDataFromStore('merchant_user').email
      } as mixpanelLogout);
    }
      , (err) => {
      }
    );
  }

  clearLocalstorage() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  //set data to session storage
  setDataInSession(key: string, value: any) {
    let data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    this.session_storage.setItem(key, data);
  }

  //get data from session storage
  getDataFromSession(key: string) {
    if (this.session_storage.getItem(key)) {
      try {
        let data = decodeURIComponent(escape(window.atob(this.session_storage.getItem(key))));
        return JSON.parse(data);
      } catch (e) {
        this.session_storage.removeItem(key);
        return false;
      }
    } else {
      return false;
    }
  }

  login(response: any, action: string) {
    if (action === 'login') {
      response && response.merchant_user ? this.setDataInStore('merchant_user', response.merchant_user) : '';
    }
    this.setDataInStore('merchant_user_auth_token', response.merchant_user.authentication_token);
    this.setDataInStore('merchantInfo', response.merchant);
    this.setDataInStore('logo', response.logo_url);
    this.injector.get(MixpanelService).identifyUser(response.merchant.id);
    this.injector.get(MixpanelService).trackEvents(MixpanelEvents.login, {
      name: response.merchant.name,
      merchant_id: response.merchant.id,
      user_id: response.merchant_user.email,
      merchant_location: response.merchant.location,
      merchant_chain_id: response.merchant.chain_id,
      source: 'business dashboard'
    } as mixpanelLogin);
  }
}
