import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css', '../css/font.css'],
})
export class DataTableComponent implements OnInit {
  @Input() table_header: any;
  @Input() table_data: any;
  @Input() config: any;
  @Input() clickable_row: boolean;
  @Input() show_zero_stat: boolean;
  @Input() noDataTemplate: TemplateRef<any>;
  @Output() action = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
  //function to return 0
  returnZero() {
    return 0;
  }
  view(data: any, view_type: string) {
    data['action'] = 'view';
    data['view_type'] = view_type;
    this.action.emit(data);
  }
  delete(data: any) {
    data['action'] = 'delete';
    this.action.emit(data);
  }
  edit(data: any) {
    data['action'] = 'edit';
    this.action.emit(data);
  }
}
