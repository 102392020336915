import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../utility/services/data.service';
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  routesBanOnChain:Array<string>=['/item-availability/categories',
    '/menu-management/categories',
    '/menu-management/variations',
    '/menu-management/addons',
    '/menu-management/upload-images',
    '/menu-management/upload-menu',
    '/item-charges',
    '/settings/gst',
    '/settings/fssai',
    '/settings/banking',
    '/settings/communication-settings',
    '/upload',
    '/settings/order-dashboard',
    '/settings/communication',
    '/settings/open-hours',
    '/settings/table-management',
    '/settings/payment-channels',
    '/settings/delivery'];
  routesBanOnSingleOutlet:Array<string>=['/leaderboard',
    '/settings/outlet-manager'];
  constructor(private router: Router,public dataService: DataService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let merchant_role:string=this.dataService.getDataFromStore('merchantInfo')?this.dataService.getDataFromStore('merchantInfo').role:null;
    if ((merchant_role==='Merchant'&&this.routesBanOnSingleOutlet.indexOf(state.url)>-1)
        ||(merchant_role==='ChainOwner'&&this.routesBanOnChain.indexOf(state.url)>-1)) {
        this.router.navigate(['overview']);
        return false;
    } else {
        return true;
    }
  }
}
