import {
  Component,
  Input,
  Output,
  EventEmitter,
  IterableDiffers,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'multi-select-box',
  templateUrl: './multi-select-box.component.html',
  styleUrls: ['./multi-select-box.component.css'],
})
export class MultiSelectBoxComponent implements OnInit {
  @Input() optionsList: any = [];
  @Input() placeholder: string;
  @Input() newButton: any;
  @Input() floatingLabel: string;
  @Input() optionalLabel: string;
  @Input() position: string;
  @Input() propertyNames: any;
  @Input() isInvalid: boolean;
  @Input() selectedValues: any = [];
  @Output() onSelection = new EventEmitter<string>();
  showSelectList: boolean = false;
  propertyToSearchFor: any;
  selections: any = null;
  differ: any;
  input_key: string = 'selections';
  constructor(differs: IterableDiffers) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.placeholder = this.placeholder ? this.placeholder : null;
    this.propertyToSearchFor = this.propertyNames.name2
      ? [this.propertyNames.name, this.propertyNames.name2]
      : [this.propertyNames.name];
  }

  ngDoCheck() {
    var changes = this.differ.diff(this.selectedValues);
    if (changes) {
      let idx: number;
      for (idx = 0; idx < this.optionsList.length; idx++) {
        let i = this.selectedValues.findIndex((selected) => {
          return selected.id === this.optionsList[idx].id;
        });
        this.optionsList[idx]['isChecked'] = i > -1 ? true : false;
      }
      this.selections = this.selectedValues
        .map((obj: any) => {
          let ele = '';
          if (this.propertyNames.name) {
            ele = this.propertyNames.name2
              ? obj[this.propertyNames.name] +
                ',' +
                obj[this.propertyNames.name2]
              : obj[this.propertyNames.name];
          } else {
            ele = obj;
          }
          return ele;
        })
        .join(', ');
    } else if (this.optionsList && this.selectedValues.length === 0) {
      let i: number;
      for (i = 0; i < this.optionsList.length; i++) {
        this.optionsList[i]['isChecked'] = false;
      }
    }
  }

  toggleSelectList(area: string) {
    if (area === 'toggle') {
      this.input_key = this.showSelectList ? 'selections' : 'search_select';
      this.showSelectList = !this.showSelectList;
    } else {
      this.showSelectList = area === 'inside' ? true : false;
      this.input_key = area === 'inside' ? 'search_select' : 'selections';
    }
  }

  selectOption(option: any) {
    this[this.input_key] = '';
    this.onSelection.next(option);
  }
}
