<div class="card__journey grid" *ngIf="cardValue !== 'loading'">
    <ng-container *ngFor="let stat of cardData?.card_name">
        <div class="journey__container">
            <div class="journey__icon">
                <mat-icon>{{ cardValue[stat]?.icon_name }}</mat-icon>
            </div>
            <span class="journey__title">
                {{ stat }} 
                <mat-icon *ngIf="stat === 'Delivered'"
                matTooltip="Some messages could not be delivered due to unforeseen user restrictions."
                matTooltipPosition="above"
                matTooltipClass="thrive-tooltip"
                class="outline info"
                >info</mat-icon
            >
            </span>
            <div class="journey__details">
                <p class="journey__header">
                    <span>Total</span>
                    <span>{{ cardValue[stat]?.total_count }}</span>
                </p>
                <div class="journey__body">
                    <p *ngIf="cardValue[stat]?.whatsapp_title">
                        <span>{{ cardValue[stat]?.whatsapp_title }}</span>
                        <span>{{ cardValue[stat].whatsapp_count ? cardValue[stat].whatsapp_count : 0 }}</span>
                    </p>
                    <p *ngIf="cardValue[stat]?.email_title">
                        <span>{{ cardValue[stat]?.email_title }}</span>
                        <span>{{ cardValue[stat].email_count ? cardValue[stat].email_count : 0 }}</span>
                    </p>
                    <p *ngIf="cardValue[stat]?.sms_title">
                        <span>{{ cardValue[stat]?.sms_title }}</span>
                        <span>{{ cardValue[stat].sms_count ? cardValue[stat].sms_count : 0 }}</span>
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- <app-skeleton-loader [type]="'box'" *ngIf="cardValue === 'loading'" [details]="{styles: {height: '280px'}}"></app-skeleton-loader> -->
