//modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from '../utility/services/interceptor.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSmoothDnDModule } from 'ngx-smooth-dnd';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { QuicklinkModule } from 'ngx-quicklink';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
// import { MatNativeDateModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// pipes
import { SafePipe } from '../utility/pipes/safe.pipe';
import { SentenceCasePipe } from '../utility/pipes/sentence-case.pipe';
// import {FormatTimePipe} from '../utility/pipes/timer.pipe';
import { MerchantFilterPipe } from '../dashboard/leaderboard/comparison/merchant_filter.pipe';
import { UsersFilterPipe } from './generic-data-table/users_filter.pipe';
import { HideShowFieldPipe } from '../utility/pipes/filed_hide_show.pipe';

// services
import { WindowRef } from '../utility/services/window.service';
import { DashboardService } from '../dashboard/dashboard.service';

// component
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { GenericDataTableComponent } from './generic-data-table/generic-data-table.component';
import { SelectBoxComponent } from './selectboxes/select-box.component';
import { MultiSelectBoxComponent } from './multi-select-box/multi-select-box.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { IconComponent } from './icon/icon.component';
import { LoaderComponent } from './loader/loader.component';
import { IntelligenceBotComponent } from '../dashboard/intelligence-bot/intelligence-bot.component';
import { SuccessFailureDialogComponent } from './success-failure-dialog/success-failure-dialog.component';
import { CustomerJourneyComponent } from './customer-journey/customer-journey.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { CustomerFeedbackComponent } from '../dashboard/feedback/customer-feedback/customer-feedback.component';
import { HorizontalSteppersComponent } from './horizontal-steppers/horizontal-steppers.component';

//directives
import { ClickOutsideDirective } from '../utility/directives/click-outside.directive';
import { DebounceClickDirective } from '../utility/directives/debounce-click.directive';
import { DropListScrollerDirective } from '../utility/directives/drop-list-scroller.directive';

//charts
import { GenericGradientChartComponent } from './charts/generic-gradient-chart/generic-gradient-chart.component';
import { GenericChartComponent } from './charts/generic-chart/generic-chart.component';
import { NonFlipChartComponent } from './charts/non-flip-chart/non-flip-chart.component';
import { ZeroStateComponent } from './zero-state/zero-state.component';
import { AdvanceSettingsComponent } from '../views/features/settings/open-hours/advance-settings/advance-settings.component';
import { StatusDialogComponent } from './status-dialog/status-dialog.component';
import { DataTableComponent } from './data-table/data-table.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
// import { TableComponentModule } from './table/table.component';
// import { deleteDialogComponent } from '../dashboard/campaign/campaign.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableComponent } from '../views/shared/table/table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ChartsComponent } from '../views/shared/charts/charts.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { BasicSettingsComponent } from '../views/features/settings/open-hours/basic-settings/basic-settings.component';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: 'yyyy-MM-dd',
    datePickerInput: 'yyyy-MM-dd',
    timePickerInput: { hour: 'numeric', minute: 'numeric' }
    // monthYearLabel: {year: 'numeric', month: 'short'},
    // dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    // monthYearA11yLabel: {year: 'numeric', month: 'long'},
};
@NgModule({
    declarations: [
        // shared component
        SubHeaderComponent,
        SkeletonLoaderComponent,
        GenericDataTableComponent,
        SelectBoxComponent,
        HorizontalSteppersComponent,
        MultiSelectBoxComponent,
        TooltipComponent,
        IconComponent,
        LoaderComponent,
        IntelligenceBotComponent,
        GenericGradientChartComponent,
        GenericChartComponent,
        NonFlipChartComponent,
        StarRatingComponent,
        CustomerFeedbackComponent,
        // pipe
        SafePipe,
        // FormatTimePipe,
        UsersFilterPipe,
        HideShowFieldPipe,
        // modal
        SuccessFailureDialogComponent,
        //directives
        ClickOutsideDirective,
        DebounceClickDirective,
        DropListScrollerDirective,
        ZeroStateComponent,
        // AddCreditsModalComponent,
        // DialogAddCreditComponent,
        StatusDialogComponent,
        DataTableComponent,
        CommonDialogComponent
        // deleteDialogComponent,
    ],
    imports: [
        CustomerJourneyComponent,
        SentenceCasePipe,
        MerchantFilterPipe,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxSkeletonLoaderModule,
        EditorModule,
        SlickCarouselModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        // MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        // MatRippleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        // MatStepperModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxPaginationModule,
        NgxMatSelectSearchModule,
        NgxSmoothDnDModule,
        TableComponent,
        ChartsComponent,
        BasicSettingsComponent,
        AdvanceSettingsComponent
    ],
    providers: [
        WindowRef,
        DashboardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        {
            provide: OWL_DATE_TIME_FORMATS,
            useValue: MY_NATIVE_FORMATS
        },
        {
            provide: OWL_DATE_TIME_LOCALE,
            useValue: 'en-IN'
        }
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxSkeletonLoaderModule,
        EditorModule,
        SlickCarouselModule,
        MatAutocompleteModule,
        MatButtonModule,
        // MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        // MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        // MatRippleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        // MatStepperModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxPaginationModule,
        NgxMatSelectSearchModule,
        // shared component
        SubHeaderComponent,
        SkeletonLoaderComponent,
        GenericDataTableComponent,
        SelectBoxComponent,
        HorizontalSteppersComponent,
        MultiSelectBoxComponent,
        TooltipComponent,
        IconComponent,
        LoaderComponent,
        IntelligenceBotComponent,
        GenericGradientChartComponent,
        GenericChartComponent,
        NonFlipChartComponent,
        StarRatingComponent,
        CustomerFeedbackComponent,
        ZeroStateComponent,
        AdvanceSettingsComponent,
        BasicSettingsComponent,
        ZeroStateComponent,
        DataTableComponent,
        // pipe
        SafePipe,
        // FormatTimePipe,
        UsersFilterPipe,
        HideShowFieldPipe,
        // modal
        // AddCreditsModalComponent,
        // DialogAddCreditComponent,
        SuccessFailureDialogComponent,
        NgxSmoothDnDModule,
        //directives
        ClickOutsideDirective,
        DebounceClickDirective,
        DropListScrollerDirective,
        CommonDialogComponent
    ]
})
export class SharedModule { }
