<mat-card
  appearance="outlined"
  class="mat-elevation-z3 overview_cards box-shadow-card"
  style="padding-bottom: 0px"
  *ngIf="showCard"
>
  <mat-card-header class="block_display">
    <mat-card-title class="overview_title"
      ><span>{{ chartData.title }}</span>
      <span
        style="float: right; color: rgb(192, 57, 43)"
        *ngIf="chartData.percent < 0"
        >{{ chartData.percent }}%</span
      >
      <span
        style="float: right; color: #30c500"
        *ngIf="chartData.percent !== '' && chartData.percent >= 0"
        >{{ chartData.percent }}%</span
      ></mat-card-title
    >
    <mat-card-subtitle class="overview_sub_title">
      {{ chartData.value }}
      <span *ngIf="chartData.quality" class="percent_values">
        <span *ngIf="chartData.value < 0" style="color: rgb(192, 57, 43)">{{
          chartData.quality
        }}</span>
        <span *ngIf="chartData.value >= 0" style="color: #63ba0e">{{
          chartData.quality
        }}</span>
      </span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content id="{{ chartData.id }}">
    <!-- <chart [options]="options"></chart> -->
  </mat-card-content>
</mat-card>
<app-skeleton-loader
  [type]="'box'"
  *ngIf="!showCard"
  [details]="{ styles: { height: '150px' } }"
></app-skeleton-loader>
